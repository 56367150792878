.crp-calendar {
  display: flex;
  align-items: baseline;

  label {
    margin-right: 1rem;
  }

  @media screen and (max-width: $mobile-width) {
    flex-direction: column;
  }
}