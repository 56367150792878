.landingpage-header{
  width: 100%;
  margin: 0 auto;
  background-color: $blue-dark;
  display: flex;
  justify-content: center;

  .title{
    color: $white;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: fit-content;

    .logo {
      position: absolute;
      width: 5rem;
      height: 5rem;
      background: url('../img/LOGO_COMBINE_BLANC_WEB.png') no-repeat center;
      background-size: contain;
      right: 19rem;
      @media all and (-ms-high-contrast: none) {
        *::-ms-backdrop, & { // IE11
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.content-landingpage{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: $desktop-width;
  margin: auto;

  .container-titles {
    margin: 30px 0;
    font-size: 15px;

    h1 {
      color: $gray-dark;
      font-size: 40px;
      margin-top: 10px;
    }
  }

  .tiles-connexion {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: $desktop-width) {
  .landingpage-header{
    div{
      padding:0;
    }
  }

  .content-landingpage{
    width: 100%;
    .container-titles {
      margin: 0;
    }

    .tiles-connexion{
      flex-direction: column;
      align-items: center;
      margin: 0;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .landingpage-header {
    font-size: 12px;

    .title {
      .logo {
        width: 4rem;
        height: 4rem;
        right: 17rem;
      }
    }
  }

  .content-landingpage{
    margin: 0;
    .container-titles {
      h1 {
        margin-top: 10px;
      }
    }

    .tiles-connexion {
      box-sizing: border-box;
    }
  }
}
