.personal-logo, .family-logo, .professional-logo, .cotisation-logo {
  width: 15rem;
  height: 10rem;
}

.user-info {
  position: relative;
  top: -1rem;
  left: 5rem;
}

.form-after-icon {
  position: relative;
  top: -3rem;
}

.personal-logo {
  display: block;
  background: url("../img/user.svg") no-repeat center;
  background-size: contain;
}

.family-logo {
  display: block;
  background: url("../img/family.svg") no-repeat center;
  background-size: contain;
}

.professional-logo {
  display: block;
  background: url("../img/worker.svg") no-repeat center;
  background-size: contain;
}

.cotisation-logo {
  display: block;
  background: url("../img/cotisation.png") no-repeat center;
  background-size: contain;
}
