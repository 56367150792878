#menu {
  z-index: 3;
  position: relative;
  margin-top: -33px;
  width: 100%;
  border-bottom: 1px solid $gray-lighter;

  & .menu-container {
    min-height: 33px;
    display: flex;
    justify-content: flex-end;
    margin-right: 6rem;
    & .menu-item {
      cursor: pointer;
      border: 1px solid $gray-light;
      border-radius: 10px 10px 0 0;
      &:not(.selected) {
        padding: 5px 10px;
        &.gray {
          color: $gray;
          border-color: $gray;
        }
        &.blue {
          color: $blue-cobalt;
          border-color: $blue-cobalt;
        }
        &.yellow {
          color: $yellow-secondary;
          border-color: $yellow-secondary;
        }
        &.orange {
          color: $orange;
          border-color: $orange;
        }
        &.blue-light {
          color: $blue-secondary;
          border-color: $blue-secondary;
        }
      }
      &.selected {
        padding: 10px 10px;
        margin-top: -5px;
        &.gray {
          color: white;
          border-color: $gray;
          background-color: $gray;
        }
        &.cobalt {
          color: white;
          border-color: $blue-cobalt;
          background-color: $blue-cobalt;

        }
        &.yellow {
          color: white;
          border-color: $yellow-secondary;
          background-color: $yellow-secondary;

        }
        &.orange {
          color: white;
          border-color: $orange;
          background-color: $orange;
        }
        &.blue {
          color: white;
          border-color: $blue-secondary;
          background-color: $blue-secondary;
        }
      }
    }
    & .menu-item:not(:last-child) {
      margin-right: 5px;
    }
  }
  .menu-burger-button {
    position: absolute;
    font-size: 1.5rem;
    color: $blue-cobalt;
    right: 4.5rem;
    top: -3.5rem;
    display: none;
  }
  .menu-burger-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    .backdrop {
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .menu-burger {
      position: fixed;
      top: 0;
      right: 0;
      background-color: $blue-cobalt;
      width: 250px;
      height: 100%;
      color: white;
      font-weight: bold;
      box-shadow: 6px 0 10px 4px black;
      .close {
        font-size: 2rem;
        position: fixed;
        top: 2rem;
        right: 2rem;
        &:hover {
          animation: rotate 0.5s;
        }
      }
      .menu {
        padding: 3rem;
        margin-top: 7rem;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .menu-item {
          list-style: none;
          cursor: pointer;
          &:hover {
            color: white;
          }
          &.selected {
            color: $yellow-secondary;
          }
        }
      }
    }
  }

  @media screen and(max-width: $desktop-width) {
    & .menu-container {
      margin-right: 3rem;
      .menu-item {
        font-size: 0.7rem;
      }
    }
  }

  @media screen and (max-width: $touchpad-width) {
    & .menu-container {
      visibility: hidden;
    }
    .menu-burger-button {
      display: block;
    }
  }
}
