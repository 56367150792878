$stroke-dash-size: 900px;
$icon-size: 20px;
$arrow-width-donnees-services: 26rem;
$arrow-width-mes-docs: 23rem;
$document-lign-size: 250px;

@mixin ieUnderline($color) {
  @media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, &::after { // IE11
      position: relative;
      bottom: -5px;
      content: '';
      display: block;
      width: 0;
      height: 2px;
      transition: width .3s;
      background: $color;
    }
  }
}

#dashboard {
  & > #banner {
    background-color: $gray-lighter;
  }
}

.multilines {
  display: flex;
  font-size: 1rem;
  flex-direction: column;
}

.text-smaller {
  margin-top: 0;
  margin-bottom: 0;
  font-size: .9rem;
}

.soulign-styled {
  border-bottom: 2px solid white;
  padding: 5px;
  box-sizing: border-box;
  width: fit-content;
  position: relative;
  margin: 1rem;

  &::before {
    content: '';
    border-radius: 50%;
    width: 7px;
    height: 7px;
    background: white;
    position: absolute;
    bottom: 5px;
    right: calc(100% + 5px);
  }
  &::after {
    content: '';
    position: absolute;
    width: 12px;
    background: white;

    height: 2px;
    transform-origin: 100% 50%;
    transform: rotate(45deg);
    right: calc(100% - 1px);
    top: 100%;
  }
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 2.2rem;
  padding-bottom: 1rem;
  margin: auto;
  overflow-x: hidden;

  .message-box-sizing-container {
    padding: 0 1rem;

    .message-container {
      display: flex;
      flex-direction: column;
      background-color: $gray-lightest;
      border-radius: 10px;
      margin-top: 30px;
      align-items: center;
      padding: 1rem;
      text-decoration: none;

      &-text {
        color: $blue-cobalt;
        line-height: 2rem;
        text-align: center;

        &-underline {
          text-decoration: underline;
        }

        a:visited {
          color: $blue-cobalt;
        }
      }
    }
  }

  .bloc-container {
    display: flex;
    flex-direction: row;
    margin: auto;
    position: relative;

    .title {
      position: absolute;
    }

    .bloc {
      box-sizing: content-box;
      margin: 1rem;
      padding: 4rem;
      border: 2px solid black;
      background-color: $white;
      position: relative;
      width: 346px;
      height: 400px;

      .title {
        position: absolute;
        width: fit-content;
        top: -5.5rem;
        left: 1.2rem;
        margin: 0;
      }

      .seperate-click {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .dashboard-no-data {
        text-align: center;
        vertical-align: middle;
        line-height: 200px;
      }

      .link {
        top: 5rem;
        padding: 5px;
        position: relative;
        margin: 0.5rem;

        &.download-button {
          top: 0;
          font-size: large;
        }

        &.--yellow {
          color: $yellow-secondary;
        }
        &.--blue {
          color: $blue-secondary;
        }
        &.--cobalt {
          color: $blue-cobalt;
        }
        &.--disabled {
          color: $gray-light;
        }
        .dashboard-label {
          position: absolute;
          left: 40px;
          font-size: 1.1rem;
          white-space: nowrap;

          &.multiline {
            display: flex;
            justify-content: space-between;
            min-width: $document-lign-size;
            white-space: inherit;
          }

          &.--yellow {
            @include ieUnderline($yellow-secondary);
          }
          &.--blue {
            @include ieUnderline($blue-secondary);
          }
          &.--cobalt {
            @include ieUnderline($blue-cobalt);
          }
          &.--disabled {
            @include  ieUnderline($gray-light);
          }
        }
        &:hover {
          .cotisation-svg, .service-svg {
            // times 1.4 to match the font size for fa icons
            width: calc(#{$icon-size} * 1.4);
            height: calc(#{$icon-size} * 1.4);
          }

          .dashboard-label {
            font-size: 1.4rem;
            @media all and (-ms-high-contrast: none) {
              *::-ms-backdrop, &::after { // IE11
                width: 100%;
              }
            }
          }
          i {
            font-size: 25px;
          }
          .container-arrow {
            .arrow-label {
              svg {
                .arrow {
                  animation: slide .5s ease-in-out forwards;
                }
              }
            }
          }
        }
        i {
          position: absolute;
          font-size: $icon-size;
        }
      }

      .yellow {
        color: $yellow-secondary;
      }

      .blue {
        color: $blue-secondary;
      }

      .cobalt {
        color: $blue-cobalt;
      }

      .bloc-button-container {
        width: 346px;
        position: absolute;
        top: 480px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .bloc-button-circle {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          align-content: center;
          border: 2px solid;
          outline: 3px solid white;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: white;
          .fa {
            font-size: 40px;
          }
          .--yellow {
            color: $yellow-secondary;
          }
          .--blue {
            color: $blue-secondary;
          }
          .--cobalt {
            color: $blue-cobalt;
          }
          .--disabled {
            color: $gray-light;
          }
        }
        .--yellow {
          border-color: $yellow-secondary;
          &:hover {
            background-color: darken($yellow-secondary, 10%);
            .fa {
              color: white;
            }
          }
        }
        .--blue {
          border-color: $blue-secondary;
          &:hover {
            background-color: darken($blue-secondary, 10%);
            .fa {
              color: white;
            }
          }
        }
        .--cobalt {
          border-color: $blue-cobalt;
          &:hover {
            background-color: darken($blue-cobalt, 10%);
            .fa {
              color: white;
            }
          }
        }
        .--disabled {
          border-color: $gray-light;
          &:hover {
            background-color: darken($gray-light, 10%);
            .fa {
              color: white;
            }
          }
        }
      }

    }

    .--yellow {
      border-color: $yellow-secondary;
    }

    .--blue {
      border-color: $blue-secondary;
    }

    .--cobalt {
      border-color: $blue-cobalt;
    }

    .--disabled {
      border-color: $gray-light;
    }
  }

}

.bloc-container.vertical {
  flex-direction: column;
}

.container-arrow {
  display: flex;
  align-items: center;

  i {
    font-size: 2rem;
  }

  .cotisation-svg {
    width: 2rem;
    height: 2rem;
    background: url('../img/cotisation_dashboard.svg') no-repeat;
  }

  .service-svg {
    position: absolute;
    width: 20px;
    height: 20px;
  }
}

.arrow-label {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;

  .text-bold {
    margin-top: 0;
    margin-bottom: 0;
  }
  svg {
    position: absolute;
    left: 0;
    width: $arrow-width-donnees-services;
  }

  .arrow-document {
    svg {
      position: absolute;
      top: -4px;
      width: $arrow-width-mes-docs;
      height: 5rem;
    }

    &--bold {
      font-weight: bold;
    }

    .arrow {
      stroke-dashoffset: $stroke-dash-size;
      stroke-dasharray: $stroke-dash-size;
      stroke-width: 3px;
      stroke: currentColor;
    }
  }
}
.arrow {
  stroke-dashoffset: $stroke-dash-size;
  stroke-dasharray: $stroke-dash-size;
  stroke-width: 3px;
  stroke: currentColor;
}

@keyframes slide {
  0% {
    stroke-dashoffset: $stroke-dash-size;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@media screen and (max-width: $big-screen-width) {
  $document-lign-size: 200px;

  .dashboard-container {
    .bloc-container {
      .bloc {
        width: 250px;
        height: 300px;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 3rem;
        padding-bottom: 5rem;

        .title {
          top: -4.5rem;
        }
        .bloc-button-container {
          width: 250px;
          top: 385px;
          .bloc-button-circle {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            .fa {
              font-size: 30px;
            }
          }
        }
        .link {
          top: 2rem;

          &.doc-list {
            // reduces top margin to fit all 4 documents
            top: -1rem;
          }

          .dashboard-label {
            font-size: 0.9rem;

            &.multiline {
              min-width: 210px;
            }
          }
          &:hover {
            .dashboard-label {
              font-size: 1rem;
            }
          }
          .container-arrow {
            .arrow-label {
              height: 2.5rem;

              &.mes-docs {
                height: 4.5rem;
              }

              svg {
                width: 20rem;
              }

              .arrow-document {
                svg {
                  position: absolute;
                  left: 0;
                  top: -4px;
                  width: 20rem;
                  height: 6rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $desktop-width) {
  .multilines {
    font-size: 1rem;
  }
  .text-smaller {
    margin-top: 0;
    margin-bottom: 0;
    font-size: .8rem;
  }
  .dashboard-container {
    padding-top: 0;
    padding-bottom: 1rem;

    .bloc-container {
      flex-direction: column;

      .bloc {
        width: 280px;
        height: 320px;
        margin-top: 5rem;
        padding-top: 2rem;
        padding-bottom: 4rem;

        .bloc-button-container {
          width: 280px;
          top: 377px;

          .bloc-button-circle {
            width: 50px;
            height: 50px;
            border-radius: 50%;

            .fa {
              font-size: 30px;
            }
          }
        }

        .link {
          top: $spacer-3;

          &.doc-list {
            top: $spacer-3;
          }

          .dashboard-label {
            font-size: $font-size-normal;
          }

          &:hover {
            .dashboard-label {
              font-size: 1rem !important;
            }
          }
          .container-arrow {
            .arrow-label {
              height: 3rem;

              &.mes-docs {
                height: 4rem;
              }

              svg {
                width: 22rem;
              }

              .arrow-document {
                svg {
                  width: 20rem;
                }
              }
            }
          }
        }
      }
    }
    .arrow-document {
      svg {
        position: absolute;
        left: 15px;
        top: -4px;
        width: 22rem;
        height: 5rem;
      }
    }
  }
}

@media screen and (max-width: $touchpad-width) {
  .dashboard-container {
    .bloc-container {
      .bloc {
        width: 280px;
        height: 320px;
        padding: $spacer-6;

        .title {
          top: -$spacer-8;
        }

        .bloc-button-container {
          top: 350px;
          width: 280px;

          .bloc-button-circle {
            width: 50px;
            height: 50px;
            border-radius: 50%;

            .fa {
              font-size: 30px;
            }
          }
        }
        .link {
          &.doc-list {
            top: 0;
          }

          .container-arrow {
            .arrow-label {
              height: 2.5rem;

              svg {
                width: 22.7rem;
              }

              .dashboard-label {
                font-size: $font-size-little;
                margin-left: $spacer-2;
              }

              .arrow-document {
                svg {
                  width: 18rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .dashboard-container {
    .bloc-container {
      .bloc {
        width: 280px;
        height: 360px;
        padding: 3rem 2rem 0 2rem;

        .title {
          top: -5rem;
          left: -1rem;
        }

        .bloc-button-container {
          top: 376px;
          left: 3rem;
        }

        .link {
          .dashboard-label {
            font-size: 0.9rem;
          }

          &:hover {
            .dashboard-label {
              font-size: 0.8rem;
            }
          }

          .container-arrow {
            .arrow-label {
              height: 3rem;

              svg {
                width: 25rem;
              }
            }

            .arrow-document {
              svg {
                width: 22rem;
              }
            }
          }
        }
      }
    }
  }
}

