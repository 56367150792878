#personal-data {
  .header {
    margin-left: $spacer-7;

    .user-info-bloc {
      .personal-data-header {
        margin-left: $spacer-4;
        width: unset;

        .label {
          font-size: $font-size-normal;
        }

        .value {
          font-size: $font-size-normal;
        }
      }
    }
  }

  .label {
    color: $blue-cobalt;
    font-size: $font-size-normal;
    font-weight: 600;

    &.asterisk::after {
      content: " *";
      color: red;
      font-size: $font-size-medium;
    }
  }

  .label-address {
    color: $blue-cobalt;
    font-size: $font-size-normal;
    font-weight: 600;
    margin-bottom: $spacer-2;
  }

  .value-address {
    margin-top: $spacer-3;
    margin-bottom: $spacer-5;
  }

  .custom-input {
    border: $border-field;

    &.disabled {
      border: none;

    }
  }

  .form {
    .select {
      border: $border-field;

      &.disabled {
        border: none;
      }
    }

    .input {
      .disabled {
        background-color: unset;
      }
    }
  }

  .main-form {
    .label {
      margin-bottom: $spacer-2;
    }

    .save-button-container .no-modification-error-text {
      margin-top: $spacer-4;
    }

    button.disabled {
      border: solid 2px $blue-cobalt;
    }
  }
}

@media screen and (max-width: $touchpad-width) {
  #personal-data {
    .header {
      margin-left: 0;

      .user-info-bloc {
        width: unset;
        padding: $spacer-5;

        .personal-data-header {
          padding: 0;
          margin: auto;

          .value {
            font-size: $font-size-normal;
          }
        }
      }
    }

    .form {
      .row {
        .title {
          text-align: center;
          width: 100%;
          margin-left: 0;
        }

        .form-description {
          display: block;
        }

        .description {
          margin-left: $spacer-4;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  #personal-data {
    .header {
      margin-bottom: $spacer-6;

      .user-info-bloc {
        .personal-data-header {
          margin-left: 0;
        }
      }
    }
  }
}
