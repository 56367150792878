$button-width: 165px;
$btn-container-max-width: 500px;

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);

  .modal-content {
    overflow: auto;
    animation: scale-in-center 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    position: relative;
    background-color: #fefefe;
    margin: 10% auto;
    padding: 3rem;
    border: 1px solid #888;
    border-radius: 5px;
    width: clamp(500px, 80vw, 800px);
    max-height: 95%;

    .modal-header {
      position: relative;

      i {
        position: absolute;
        right: 0;
        cursor: pointer;
      }

      &__title {
        text-align: center;
        color: $blue-cobalt;

        &.left-align {
          text-align: left;
        }

        h1 {
          margin-top: 0;
        }
      }
    }

    .modal-body {
      text-align: center;
    }

    &__subtitle {
      font-weight: bold;
      margin-top: 30px;
      font-size: 22px;
    }

    &__text {
      font-weight: 300;
      margin-top: 20px;
      line-height: 1.5;
    }

    &__subtext {
      width: 60%;
      margin: auto;
      text-align: start;
    }

    & li {
      line-height: 1.5;
    }

    &.modal-confirmation {
      .modal-body {
        text-align: center;
      }

      .modal-footer {
        max-width: $btn-container-max-width;
        margin: auto;

        .btn-custom {
          width: 100%;
        }

        .btn-confirmation {
          button {
            height: 2.5rem;
            margin: $spacer-6;
            font-size: $font-size-medium;
          }
        }

        .btn-cancel {
          button {
            height: 2.5rem;
            margin: $spacer-6;
            color: $blue-cobalt;
            background-color: white;
            border: 1px solid $blue-cobalt;
            font-size: $font-size-medium;
          }
        }
      }
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      width: 100%;
      min-height: 60px;

      .btn-custom {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .btn-cancel {
        button {
          width: 100%;
          max-width: $button-width;
          margin: 0;
          color: $blue-cobalt;
          background-color: white;
          border: 1px solid $blue-cobalt;
        }
      }

      .btn-confirmation {
        button {
          width: 100%;
          max-width: $button-width;
        }
      }

      button {
        margin-top: 40px;
        margin-bottom: 0;

        &--error {
          background: $errorColor;

          &:hover {
            background: darken($errorColor, 10%);
          }
        }

        & span {
          font-size: 16px;
        }
      }
    }
  }

  .helper-modal {
    margin: 1% auto;
  }

  .delegation-modal {
    padding-bottom: 3rem;

    .modal-header__title {
      text-align: left;
      color: $blue-cobalt;
    }

    &-content-text {
      font-size: 1.1rem;
      text-align: left;
      margin-top: 30px;
      margin-bottom: 60px;
    }

    .btn-wrapper {
      display: block;

      button {
        height: 3rem;
        line-height: 16px;
        padding: 1rem 5rem;
        margin: 0 10px;
      }

      .blue {
        background-color: $blue-cobalt;
      }

      .white {
        color: $blue-cobalt;
        background-color: $white;
        border: 1px solid $blue-cobalt;
      }
    }
  }
}

.end-union-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);

  .modal-content {
    position: relative;
    width: clamp(500px, 80vw, 800px);
    max-height: 95%;
    margin: 10% auto;
    padding: 3rem;
    background-color: #fefefe;
    border: 1px solid #888;
    border-radius: $border-radius-1;
    animation: scale-in-center 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    .modal-header {
      position: relative;

      i {
        position: absolute;
        right: 0;
        cursor: pointer;
      }

      &__title {
        text-align: center;
        color: $blue-cobalt;

        h1 {
          margin-top: 0;
        }
      }
    }

    .modal-body {
      text-align: center;
    }

    &__subtitle {
      margin-top: $spacer-6;
      font-weight: bold;
      font-size: $font-size-large;
    }

    &__text {
      margin-top: $spacer-5;
      font-weight: 300;
      line-height: 1.5;
    }

    &__subtext {
      text-align: start;
      width: 60%;
      margin: auto;
    }

    & li {
      line-height: 1.5;
    }

    &.modal-confirmation {
      .modal-body {
        text-align: center;
      }

      .modal-footer {
        width: 60%;
        margin: 0 auto;

        .btn-custom {
          width: 100%;
        }

        .btn-confirmation {
          button {
            width: unset;
            height: 2.5rem;
            margin: $spacer-6;
            font-size: $font-size-medium;
          }
        }

        .btn-cancel {
          button {
            width: unset;
            height: 2.5rem;
            margin: $spacer-6;
            font-size: $font-size-medium;
            background-color: $gray-lighter;
            color: $gray-darker;
          }
        }
      }
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      width: 100%;
      min-height: 60px;

      .btn-custom {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .btn-cancel {
        button {
          width: 100%;
          max-width: $button-width;
          color: $blue-cobalt;
          background-color: white;
          border: 1px solid $blue-cobalt;
        }
      }

      .btn-confirmation {
        button {
          width: 100%;
          max-width: $button-width;
        }
      }

      button {
        margin-top: $spacer-7;
        margin-bottom: 0;

        &--error {
          background: $errorColor;

          &:hover {
            background: darken($errorColor, 10%);
          }
        }

        & > span {
          font-size: $base-font-size;
        }
      }
    }
  }
}

@media screen and (max-width: $touchpad-width) {
  .modal {
    .modal-content {
      padding: $spacer-6;

      .modal-footer {
        button {
          width: 80%;
          margin: $spacer-5;
        }
      }

      &.modal-confirmation {
        .modal-footer {
          align-items: center;
          margin-top: $spacer-4;

          .btn-confirmation, .btn-cancel {
            button {
              margin: $spacer-2;
            }
          }
        }
      }
    }
  }

  .end-union-modal {
    .modal-content {
      .modal-footer {
        width: 100%;
      }
    }
  }
}

@keyframes scale-in-center {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media screen and (max-width: $custom-width) {
  .modal .modal-content {
    padding: $spacer-5;

    &.modal-confirmation {
      .modal-footer {
        flex-direction: column-reverse;
        flex-wrap: wrap;
      }
    }
  }

  .end-union-modal {
    .modal-content {
      .modal-footer {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        width: 100%;
        margin: 0 auto;
        padding-top: $spacer-4;

        .btn-custom {
          &.btn-confirmation {
            button {
              width: 100%;
              margin: $spacer-3;
            }
          }

          &.btn-cancel {
            button {
              width: 100%;
              margin: $spacer-3;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .modal .modal-content {
    padding: $spacer-4;
  }

  .end-union-modal {
    .modal-content {
      .modal-header {
        &__title {
          padding: 0 $spacer-4;
        }
      }
    }
  }
}