@mixin color($color) {
  &.#{$color} {
    border: 2px solid;
    @if($color == "cobalt") {
      border-color: $blue-cobalt;
    } @else if($color == "yellow") {
      border-color: $yellow-secondary;
    } @else {
      border-color: map-get($file-colors, $color);
    }
    &:hover {
      @if($color == "cobalt") {
        background: $blue-cobalt;
      } @else if($color == "yellow") {
        background: $yellow-secondary;
      } @else {
        background: map-get($file-colors, $color);
      }
      color: white;

    }
  }
}

@mixin IconBlocColor($color) {
  &.#{$color} {
      background: map-get($file-colors, $color);
      @if $color == "yellow" {
        color: black;
      } @else {
        color: white;
      }
  }
}

@mixin Icon($icon) {
  display: block;
  background: url($icon) no-repeat center center;
  background-size: contain;
  -webkit-filter: invert(100%);
}

@mixin BlackIcon($icon) {
  display: block;
  background: url($icon) no-repeat center center;
  background-size: contain;
  -webkit-filter: invert(0%);
}

$width-square : 200px;
$height-square: 150px;

.tier-documents, .tier-cotisation {
  background-color: $white;
  border: solid 3px $blue-secondary;
  border-radius: 0;
  padding: $spacer-8;
  position: relative;

  &-title {
    display: inline-block;
    position: absolute;
    top: 0;

    .position {
      position: relative;
      left: 2%;
    }

    &-background {
      top: -2rem;
      height: 5px;
      width: 100%;
      background-color: white;
    }

    h2 {
      z-index: 2;
      top: -1.5rem;
      color: $blue-secondary;
      margin: 0;
      padding: 0 0.5rem;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .notaires-filter {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      // TODO: fix le calcul qui n'est pas parfait
      // taille des blocs de catégories de docs moins (le padding du composant container + la margin des blocs de chaque extremité)
      max-width: calc(960px - 2 * (#{$spacer-6} + #{$spacer-3}));
      width: 90%;
      margin: auto;
      margin-bottom: $spacer-7;
      padding: $spacer-4 0;
      background-color: white;
      border: 2px solid $blue-cobalt;

      &.grey {
        width: 100%;
        max-width: unset;
        margin: 0 0 $spacer-7 0;
        background-color: $gray-lightest;
        border: none;
        border-radius: 1rem;
      }

      &.cotisation-data {
        .to {
          &-left {
            position: absolute;
            left: 10%;
          }

          &-right {
            position: absolute;
            right: 10%;
          }
        }
      }

      .arrow {
        cursor: pointer;

        &-blue {
          color: $blue-cobalt;
        }

        &-dark {
          color: $gray-light;
        }
      }

      .to {
        &-left {
          position: absolute;
          left: 10%;
        }

        &-right {
          position: absolute;
          right: 10%;
        }
      }

      .notaires {
        display: inherit;

        .notaire {
          display: flex;
          flex-direction: column;
          text-align: center;
          align-items: center;
          width: 8rem;
          margin: 0 1rem;
          cursor: pointer;

          &.conjoint-coll {
            margin-left: 0;
          }

          &.selected-notaire {
            margin-right: 0;
          }

          &.active {
            color: $blue-cobalt;
          }

          &.inactive {
            color: black;
          }

          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }

          &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: white;
            width: 50px;
            height: 50px;
            margin-bottom: 1rem;
            position: relative;
            box-shadow: 0 2px 3px $gray-light;

            &:hover {
              transform: scale(1.2);
            }

            .tick {
              position: absolute;
              top: 0;
              right: 0;
            }
          }

          &-infos {
            width: 100%;

            p {
              margin: 0.2rem 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }

        .conjoint-coll-line {
          width: 2rem;
          margin-bottom: auto;
          margin-top: $spacer-5;
          border: 3px solid rgba(211, 211, 211, 1);
          border-radius: $border-radius-2;
        }
      }
    }

    .files-wrapper {
      display: flex;
      flex-direction: row;

      .files-filter {
        // 1/3 of the files-wrapper width
        flex-basis: 33%;
        padding: 0;

        &-title {
          margin-top: 0;
          color: $blue-cobalt;
        }

        .filter-document {
          line-height: 2rem;

          label {
            cursor: pointer;
          }

          label > span {
            font-family: $font-family;
          }

          input {
            margin-right: 1rem;
          }
        }
      }

      .column-wrapper {
        // 2/3 of the files-wrapper width
        flex-basis: 67%;

        .no-documents {
          padding: 2rem;
        }

        .show-more {
          padding-top: 1rem;
          text-align: center;

          button {
            border: none;
            background-color: white;
            text-decoration: underline;
            font-family: $font-family;
            font-size: 1.2rem;
          }
        }

        .files {
          display: flex;
          flex-direction: column;

          .document-item {
            display: flex;
            align-items: center;
            padding: 0.5rem 3rem 0.5rem 0;

            div.icon-image {
              margin-right: 3rem;
              width: 20px;
              height: 20px;
              @include BlackIcon("../img/file.svg");

              &.deco {
                @include BlackIcon("../img/euro.svg");
              }

              &.estr {
                @include BlackIcon("../img/graph.svg");
              }

              &.fict {
                @include BlackIcon("../img/setting.svg");
              }

              &.attf {
                @include BlackIcon("../img/contract.svg");
              }

              &.point_repurchase {
                @include BlackIcon("../img/file.svg");
              }
            }

            &-infos {
              font-size: 1rem;
              font-family: $font-family;

              p {
                margin: 0;
              }
            }

            .icon {
              text-align: center;
              margin-left: auto;
              color: white;
              border-radius: 15px;
              background: $blue-cobalt;
              min-width: 30px;
              height: 30px;
              display: flex;
              box-shadow: 0 6px 5px $gray-light;
              cursor: pointer;
              @media all and (-ms-high-contrast: none) { // IE
                padding-top: 0.5rem;
              }
              &:before {
                margin: auto;
              }
            }
          }
        }
      }
    }
  }
}

.grid-style {
  @supports (display: contents) {
    display: contents;
  }
  display: inline-table;

  .square-file-bloc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:not(.--disabled) {
      &:hover {
        transform: scale(1.1);
        font-weight: bold;
      }
    }

    .image {
      text-align: center;
      height: $height-square;
      width: $width-square;
      padding: 1rem;
      margin: .5rem;

      .icon-image {
        width: 65%;
        height: 65%;
        margin: 20px auto auto;
        @include Icon("../img/file.svg");

        &.deco {
          @include Icon("../img/euro.svg");
        }

        &.estr {
          @include Icon("../img/graph.svg");
        }

        &.fict {
          @include Icon("../img/setting.svg");
        }

        &.attf {
          @include Icon("../img/contract.svg");
        }

        &.point_repurchase {
          @include Icon("../img/file.svg");
        }
      }

      &.--disabled {
        background-color: $gray-light;
      }

      &.grey {
        @include IconBlocColor("grey");
      }

      &.blue {
        @include IconBlocColor("blue");
      }

      &.green-light {
        @include IconBlocColor("green-light");
      }

      &.yellow {
        background-color: $yellow-secondary;
      }

      &.blue-light {
        @include IconBlocColor("blue-light");
      }

      &.green {
        @include IconBlocColor("green");
      }

      &.turquoise {
        @include IconBlocColor("turquoise");
      }

      &.cobalt {
        background-color: $blue-cobalt;
      }
    }

    .file-square {
      padding: 1rem;
      margin: 1rem;
      background: white;
      text-align: center;
      align-items: center;
      width: $width-square;
      height: $height-square;

      .text-icon {
        margin: auto;
        font-size: large;
      }

      &.--disabled {
        color: $gray-light;

        &:hover {
          cursor: not-allowed;
        }
      }
      &:not(.--disabled) {
        @include color("blue");
        @include color("grey");
        @include color("blue-light");
        @include color("green");
        @include color("turquoise");
        @include color("green-light");
        @include color("yellow");
        @include color("cobalt");
      }

      &.cursor {
        cursor: pointer;
      }
    }
  }
}

#file-detail, #services-ris, #retreat, #contributor-certification, #pack-payment, #pack-fiscal, #certificate-retirement {
  .files {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 500px;
    .file {
      display: flex;
      justify-content: space-between;
      height: 50px;
      width: 100%;
      .icon {
        text-align: center;
        vertical-align: center;
        color: white;
        border-radius: 15px;
        background: $blue-cobalt;
        width: 30px;
        height: 30px;
        display: flex;
        box-shadow: 0 6px 5px $gray-light;
        cursor: pointer;
        @media all and (-ms-high-contrast: none) { // IE
          padding-top: 0.5rem;
        }
        &:before {
          margin: auto;
        }
      }
    }
  }
}

#services-ris {
  .bloc{
    p {
      line-height: 1.5;
    }
  }
}

@media all and (max-width: $desktop-width) {
  #file-detail {
    .files {
      // margin-top: 3rem;
    }
  }

  .tier-documents, .tier-cotisation {
    &-content {
      .notaires-filter {
        &.cotisation-data {
          .to {
            &-left {
              left: 5%;
            }

            &-right {
              right: 5%;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: $notepad-width) {
  .tier-documents, .tier-cotisation {
    &-content {
      .notaires-filter {
        .to {
          &-left {
            left: 6%;
          }

          &-right {
            right: 6%;
          }
        }
      }
    }
  }
}

@media all and (max-width: $touchpad-width) {
  #file-detail, #services-ris, #retreat, #contributor-certification, #pack-payment, #pack-fiscal, #certificate-retirement {
    .files {
      min-width: auto;
      .file {
        margin: 1rem;
        width: 80%;
        .icon {
          margin-left: 1rem;
        }
      }
    }
  }

  .tier-documents, .tier-cotisation {
    &-content {
      .notaires-filter {
        &.cotisation-data {
          .to {
            &-left {
              left: 17%;
            }

            &-right {
              right: 17%;
            }
          }
        }

        .arrow {
          margin: auto;
        }

        .to {
          &-left {
            left: 20%;
          }

          &-right {
            right: 20%;
          }
        }

        .notaires {
          .notaire {
            margin: 0 0.5rem;
          }
        }
      }
      .files-wrapper {
        .files-filter {
          display: none;
        }

        .column-wrapper {
          flex-basis: 100%;

          .files {
            .document-item {
              padding: 0.5rem 1rem;
            }
          }
        }
      }
    }
  }

}

@media all and (max-width: $custom-width) {
  #file-detail {
    .files {
      .file {
        width: 100%;
      }
    }
  }

  .square-file-bloc {
    max-width: 350px;
  }
}

@media all and (max-width: $mobile-width) {
  .tier-documents, .tier-cotisation {
    &-title {
      position: relative;
    }

    &-content {
      .notaires-filter {
        &.cotisation-data {
          .to {
            &-left {
              left: 10%;
            }

            &-right {
              right: 10%;
            }
          }
        }

        .to {
          &-left {
            left: 15%;
          }

          &-right {
            right: 15%;
          }
        }
      }
    }
  }
}

// .tier-documents-title wrap à 360px
// Le style est donc adapté pour correspondre
@media all and (max-width: 360px) {
  .tier-documents {
    &-title {
      &-background {
        top: calc(-4rem + 2px);
        width: 75%;
      }
    }
  }
}

