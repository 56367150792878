.disabled {
  .tooltip {
    cursor: unset;
    color: $gray-light;
  }

  .tooltip:hover .tooltip-text{
    visibility: hidden;
  }
}

.page-to-be-released, .tooltip {
  position: absolute;
  right: 6px;
  color: $gray-dark;
  cursor: pointer;
  text-align: center;

  &.page-to-be-released {
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    text-transform: none;
  }

  .tooltip-text {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    width: 10rem;
    bottom: 100%;
    left: 50%;
    background-color: $blue-gray;
    padding: 0.5rem;
    margin: 0.5rem 0.5rem 0.5rem -1.5rem;
    color: $white;
    border-radius: .5rem;

    &::after {
      content: " ";
      position: absolute;
      top: 100%;
      right: 84%; /* At the bottom of the tooltip */
      //margin-left: 1.5px;
      border-width: 5px;
      border-style: solid;
      border-color: $blue-gray transparent transparent transparent;
    }
  }

  .no-file-available {
    position: relative;
    top: 45%;
    left: 0;
    font-size: 17px;
  }

  &.left {
    .tooltip-text {
      margin-left: -9.5rem;
      &::after {
        right: 10%;
      }
    }
  }
}

.tooltip:hover .tooltip-text{
  visibility: visible;
}
