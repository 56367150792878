#request-monitoring {

  .container {
    width: 800px;

    .request-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 1.5rem;

      .informations {
        display: flex;
        flex-direction: column;

        .date {
          font-size: small;
        }
      }

      .status {
        font-weight: bold;
      }
    }
  }
}

@media all and(max-width: $touchpad-width) {
  #request-monitoring {
    display: block;
    width: 100%;
    .request-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: auto;
      margin: 1.5rem;
      .status {
        margin: 1rem;
      }
    }
  }
}