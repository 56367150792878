#family-data {
  .split-border {
    border-top: $split-border;

    .conjoint-instruction {
      margin-top: $spacer-4;
      color: $blue-cobalt;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .seperate-between-family {
    margin-bottom: $spacer-1;

    .family-consult {
      font-size: $font-size-larger;
      display: block;
    }
  }

  .form {
    color: black;

    .description-subtitle {
      color: $blue-cobalt;
      text-transform: uppercase;
    }

    .subtitle-family {
      text-transform: uppercase;
    }

    .conjointUpdate {
      font-size: $font-size-normal;
      font-weight: 600;
    }

    .childrenDescription {
      margin-top: $spacer-2;
      text-transform: unset;
      font-weight: 400;
    }

    .add {
      width: 100%;
      text-align: end;
    }

    .row {
      input {
        border-bottom: unset;
      }
      select {
        border-bottom: unset;
      }

      .text-container {
        h3 {
          margin: $spacer-4;
          padding-left: $spacer-6;
          color: $blue-cobalt;
        }
      }

      .label {
        margin-bottom: $spacer-1;
        color: $blue-cobalt;
        font-size: $base-font-size;
        font-weight: 600;
      }

      .field {
        height: 3rem;
        padding: $spacer-1;
        border: $border-field;
        border-radius: $border-radius-1;

        .material-input-coreoz {
          border: unset;
        }

        input:disabled {
          background-color: unset;
        }
      }

      .children {
        background-color: $bg-color-form;
        border-radius: $border-radius-3;
        padding: 0;
        margin: $spacer-4 0;

        .row {
          padding: $spacer-4 $spacer-6 0 $spacer-6;
        }

        .child-header {
          padding: $spacer-4 $spacer-6;
          border-bottom: $split-border;

          h3 {
            margin: 0;
            padding: $spacer-1 0 0 0;
          }
        }
      }
    }
  }

  .union-field {
    height: 3rem;
    width: 100%;
    padding: $spacer-3 $spacer-4;
    margin-top: $spacer-1;
    background-color: white;
    border: 1px solid rgba(129, 138, 145, .5);
    border-radius: 3px;
    font-size: $font-size-medium;

    &.disabled {
      background-color: $gray-lighter;
      opacity: 0.8;
    }
  }

  .character {
    height: 3rem;
    width: 100%;
    padding: $spacer-3 $spacer-4;
    margin-top: $spacer-1;
    border: 1px solid rgba(129, 138, 145, .5);
    border-radius: 3px;
    font-size: $font-size-medium;

    &:disabled {
      color: rgba(0, 0, 0 , 0.4);
      background-color: $gray-lighter;
      opacity: 0.8;
    }
  }
}

.add-child-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  cursor: pointer;
  background-color: white;
  border: 0;

  .fa {
    display: inline-flex;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    margin-right: $spacer-4;
    border-radius: $border-radius-4;
    background-color: $blue-cobalt;
    color: white;
    box-shadow: $gray 0 2px 5px;

    &::before {
      margin: auto;
    }
  }

  &:hover {
    .add-child-link {
      text-decoration: underline;
    }
  }
}

.child-form {
  margin-bottom: 2rem;
  background-color: rgba(207, 216, 243, 0.2);
  border-radius: 10px;

  .child-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem 0 1rem;
    border-bottom: solid 1px rgba(32, 77, 156, 0.4);
    color: $blue-cobalt;

    .delete-button-container {
      position: relative;
      display: flex;
      align-items: center;

      .fa {
        position: absolute;
        left: 10%;
        z-index: 1;
        color: white;
      }
    }
  }

  .save-button-container {
    margin-bottom: $spacer-8;
  }

  .children-data {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem 0 1rem;
    margin-top: 0;

    select {
      width: 100%;
      height: 3rem;
      padding: 5px;
      background-color: white;
      border: $border-field;
      border-radius: 3px;
    }

    .character {
      height: 3rem;
      width: 100%;
      padding: 5px;
      border: $border-field;
      border-radius: 3px;
    }
  }
}

.field-label {
  position: relative;
  color: $blue-cobalt;
  font-size: $base-font-size;
  font-weight: 600;

  &.disabled {
    opacity: 0.8;
  }

  .fa-asterisk {
    position: absolute;
    margin-left: $spacer-1;
    font-size: $font-size-mini;
    color: red;
  }
}

.end-union-form {
  margin-bottom: $spacer-2;
  border-radius: $border-radius-3;

  .end-union-container {
    display: flex;
    flex-direction: column;

    .end-union-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $spacer-6 $spacer-4 0 $spacer-4;
      margin-top: 0;

      .end-union-field-label {
        width: 50%;
      }

      select {
        width: 100%;
        height: 3rem;
        margin-top: $spacer-1;
        padding: $spacer-3 $spacer-4;
        background-color: white;
        border: $border-field;
        border-radius: $border-radius-1;
        font-size: $font-size-medium;
      }

      .date-picker-field {
        height: 3rem;
        margin-top: $spacer-1;
        border: $border-field;
        border-radius: $border-radius-1;
        background-color: white;

        .calendar-icon {
          position: absolute;
          z-index: 1;
          display: flex;
          margin: $spacer-3 0 0 $spacer-3;
          font-size: $font-size-large;

          &.disabled {
            opacity: 0.8;
          }
        }

        .datepicker {
          height: 100%;

          .rdt {
            height: 100%;
            margin-left: 40px;

            .material-input-coreoz {
              height: 100%;
              border: none;
              font-size: $font-size-medium;
            }
          }
        }
      }

      & > label {
        text-align: left;
      }
    }
  }
}

.past-union-form {
  margin-bottom: $spacer-9;
  background-color: $bg-color-form;
  border-radius: $border-radius-3;

  .union-period {
    margin-left: $spacer-2;
    font-weight: 400;
  }

  .past-union-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 $spacer-6;
    border-bottom: $split-border;
    color: $blue-cobalt;
  }

  .past-union-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &.margin-read-only {
      margin-bottom: $spacer-6;
    }

    .past-union-data {
      display: flex;
      flex-direction: column;
      padding: $spacer-5 $spacer-4 0 $spacer-4;
      margin-top: 0;

      .date-picker-field {
        height: 3rem;
        width: 100%;
        margin-top: $spacer-1;
        border: $border-field;
        border-radius: $border-radius-1;
        background-color: white;

        &.unset-bg {
          background-color: unset;
        }

        .calendar-icon {
          display: flex;
          position: absolute;
          z-index: 1;
          margin: $spacer-3 0 0 $spacer-3;
          font-size: $spacer-5;

          &.disabled {
            opacity: 0.8;
          }
        }

        .datepicker {
          height: 100%;

          &.disabled {
            background-color: $gray-lighter;
            opacity: 0.8;
          }

          .rdt {
            height: 100%;
            margin-left: 40px;

            .material-input-coreoz {
              height: 100%;
              border: none;
              font-size: $font-size-medium;
            }
          }
        }
      }

      & > label {
        text-align: left;
      }
    }
  }
}

.conjoint-container, .children-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 3rem;

  &.margin-read-only {
    margin-bottom: $spacer-6;
  }

  .conjoint-link {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;

    .MuiLink-button {
      font-family: $font-family;
    }

    .past-union-links {
      margin-top: $spacer-2;

      &--disabled {
        cursor: not-allowed;
      }
    }
  }

  .date-picker-field {
    height: 3rem;
    width: 100%;
    margin-top: $spacer-1;
    border: 1px solid rgba(129, 138, 145, 0.5);
    border-radius: 3px;
    background-color: white;

    &.unset-bg {
      background-color: unset;
    }

    .datepicker {
      height: 100%;
      border-radius: 2px;

      &.disabled {
        background-color: $gray-lighter;
        opacity: 0.8;
      }

      .rdt {
        height: 100%;
        margin-left: 40px;

        .material-input-coreoz {
          height: 100%;
          border: none;
          font-size: $font-size-medium;

          &:disabled {
            background-color: $gray-lighter;
          }
        }
      }
    }
  }

  .error-message {
    margin-top: 5px;
  }
}

@media screen and (max-width: $touchpad-width) {
  .data-container {
    .page-container #family-data, #children-data {
      .conjoint-container, .children-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        grid-gap: 1rem;
      }
    }
  }

  .past-union-form {
    .past-union-container {
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  .conjoint-container, .children-container {
    .conjoint-link {
      display: flex;
      align-items: center;
    }
  }

  .end-union-form {
    .end-union-container {
      .end-union-data {
        .end-union-field-label {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  #family-data {
    .form .row .children {
      padding: 5px;
      margin: 1rem 0;

      .row {
        padding: 0.5rem 1rem 0 1rem;
      }

      .child-header {
        padding: 0.5rem 1rem;

        .form-button {
          padding: 0;
          // margin: 4px;
          width: 35px;
          height: 35px;

          i {
            margin-top: 11px;
            margin-left: 12px;
            visibility: visible;
          }

          span {
            visibility: hidden;
          }
        }
      }
    }
  }
}
