$base-font-size: 14px;

$left-menu-nav-width: 16rem;

/* bootstrap override */
$enable-flex: true;

$brand-primary: #1469b3;

$gray-darker: #2a2d2f;
$gray-dark: #4D4D4D;
$gray: #55595c;
$gray-light: #818a91;
$gray-lighter: #eceeef;
$gray-lightest: #f7f7f9;

$gray1: #ebebeb;
$gray2: #f3f3f3;
$gray3: #C0C0C0;
$gray4: #F0EFEF;
$gray5: #EAE9E9;
$gray6: #F7F6F6;
$gray7: #A5A5A5;

$blue1: #7E94C4;
$blue-light: #98B6CF;

$red-danger: #DC3149;
$green-success: #3DA556;

$blue-cobalt: #204D9C;
$orange: #FF914D;

$blue-secondary: #007AC1;
$blue-dark: #444E66;
$yellow-secondary: #F9B330;
$blue-gray: #94B6CE;
$white: #ffffff;

$retraite: #FAB430;
$beneficaire: #C9E265;
$horsexercice: #94B6CE;

$errorColor: #e44043;

// Alertes
$INFP: $blue-light;
$ALER: $red-danger;
$INFG: $yellow-secondary;

$idnot-color: #266fa9;

$file-colors: (
        'blue': #2A7BC3,
        'grey': #454E67,
        'blue-light': #359ED4,
        'green': #46D8A8,
        'turquoise': #3FBDC9,
        'green-light': #9FEC80,
        'yellow': #F8F867,
);

$colors: (
        'blue': $blue-secondary,
        'cobalt': $blue-cobalt,
        'yellow': $yellow-secondary,
);

$body-bg: $gray-lighter;

$service-bg: rgba(249, 179, 48, 0.09);
$delegation-bg: rgba(20, 10, 249, 0.05);
$file-bg: rgba(233, 243, 249, 1);
$accordion-bg: rgba(245, 246, 250, 1);
$bg-color-form: rgba(207, 216, 243, 0.2);

$split-border: 1px solid rgba(32, 77, 156, 0.4);
$border-field: 1px solid rgba(129, 138, 145, .5);

$switch-bg: #ccc;
$switch-color: #2196F3;

$headings-font-weight: 300;

// font-size
$font-size-mini: 0.4rem;
$font-size-small: 0.7rem;
$font-size-little: 0.9rem;
$font-size-normal: 1rem;
$font-size-medium: 1.2rem;
$font-size-large: 1.5rem;
$font-size-larger: 1.8rem;
$font-size-extra-large: 2rem;
$font-size-extra-larger: 2.5rem;
$font-size-huge: 3rem;

// padding / margin
$spacer-1: 0.25rem;
$spacer-2: 0.5rem;
$spacer-3: 0.75rem;
$spacer-4: 1rem;
$spacer-5: 1.5rem;
$spacer-6: 2rem;
$spacer-7: 3rem;
$spacer-8: 4rem;
$spacer-9: 5rem;
$spacer-10: 10rem;

$border-radius-1: 3px;
$border-radius-2: 5px;
$border-radius-3: 10px;
$border-radius-4: 32px;

$font-family: 'Gotham Light', sans-serif;

$width: 940px;

$cotisation-data-row-padding: 0.5rem;

$mobile-width: 450px;
$custom-width: 621px;
$touchpad-width: 768px;
$notepad-width: 860px;
$desktop-width: 1100px;
$big-screen-width: 1500px;

@mixin jesuis($size) {
  .jesuis {
    margin: auto;
    color: $white;
    height: 3rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: fit-content;
    position: relative;

    span {
      top: 0;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      position: absolute;
      font-size: 12px;
      &:before {
        content: '';
        border-top: 1px solid white;
        left: 0;
        top: calc(50% + 2px);
        position: absolute;
        height: 1px;
        width: $size;
      }
    }

    b {
      border-bottom: 1px solid $white;
      border-left: 1px solid $white;
      padding: 10px 12px 6px 12px;
      font-size: 16px;
    }

  }
}
