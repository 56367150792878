.accordion-section {
  .accordion-section-header {
    cursor: pointer;
    padding: 1.5rem 0 2rem 0;
    display: flex;
    align-items: center;

    .title {
      font-size: $font-size-medium;
      color: $blue-cobalt;
      text-transform: uppercase;
      padding-right: 1rem;
    }

    i {
      color: $blue-cobalt;
      transition: transform 0.2s;

      &.up {
        transform: rotate(180deg);
      }
    }
  }

  .accordion {
    box-shadow: none;
  }

  .MuiExpansionPanel-root.Mui-expanded {
    margin: 0;
  }

  .MuiExpansionPanel-root.Mui-expanded:before {
    opacity: 1;
  }
}

.accordion-item {
  background-color: $accordion-bg;
  border-radius: 5px;
  margin-bottom: 1rem;

  .accordion-item-header {
    min-height: 40px;
    padding: 0.5rem;
    align-items: center;
    cursor: pointer;
    font-weight: bold;

    &.up {
      border-bottom: 1px solid $gray-lighter;
    }

    .dropdown-icon {
      display: flex;
      flex-basis: 40%;
      justify-content: flex-end;

      i {
        color: $blue-cobalt;
        padding: 0 0.5rem;
        transition: transform 0.2s;

        &.up {
          transform: rotate(180deg);
        }
      }
    }
  }

  .accordion-item-content {
    .subheader {
      padding-top: $spacer-2;
    }
    .ligne {
      padding-top: $spacer-2;
    }
  }

  .accordion {
    background-color: $accordion-bg;
  }


  .accordion {
    box-shadow: none;
  }
  .MuiExpansionPanel-root:before {
    opacity: 0;
  }
  .MuiExpansionPanel-root.Mui-expanded {
    margin: 0;
  }
  .MuiExpansionPanel-root.Mui-expanded:before {
    opacity: 0;
  }
}

.regime {
  .accordion-item-content {
    padding: 0.5rem;

    .subheader {
      color: $blue-cobalt;
    }

    .subheader, .ligne {
      display: flex;

      .item {
        display: flex;
        flex-basis: 20%;
        justify-content: center;
        text-align: center;
      }
    }
  }

  .motif {
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.regime-complementaire-b, .regime-complementaire-c {
  .accordion-item-content {
    .ligne, .subheader {
      .dates {
        display: flex;
        flex-basis: 25%;
        justify-content: center;
        text-align: center;
        align-items: center;
      }

      .item {
        text-align: right;
      }

      .item-rcb {
        display: flex;
        flex-basis: 25%;
        justify-content: center;
        text-align: center;
      }

      .item-c {
        display: flex;
        flex-basis: 25%;
        justify-content: center;
        text-align: center;
        align-items: center;
      }

      .moy {
        display: flex;
        flex-basis: 25%;
        justify-content: center;
        text-align: center;
      }
    }

  }
}

.regime-base {
  .accordion-item-content {
    .subheader, .ligne {
      .motif {
        flex-basis: 16%;
      }
    }
  }
}

@media screen and (max-width: $touchpad-width) {
  .regime {
    .accordion-item-content {
      .subheader, .ligne {
        .item {
          flex-basis: 20%;
        }
        .dates {
          flex-basis: 31%;
        }
        .item-rcb, .item-c {
          flex-basis: 23%;
        }
        .moy {
          flex-basis: 27%;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .regime {
    .accordion-item-content {
      .subheader, .ligne {
        .dates {
          flex-basis: 25%;
        }
        .item-rcb, .item-c {
          flex-basis: 25%;
        }
      }
    }
  }
}

