#retreat-data {
  .data-bloc {
    padding: 0 2rem;
  }

    .label {
      margin-bottom: 8px;
      color: $blue-cobalt;

    }

    .description-line {
      margin-bottom: 1.5rem;
      display: block;

    }

  .row {
    display: flex;

    .description-line{
      width: 50%;
      display: block;
    }
    .col {
      width: 50%;

    }
  }

  .limit {
    height: 1px;
    opacity: 0.4;
    margin: 10px auto ;
    background-color: rgba(32, 77, 156, 0.4);
  }

  .next-paiement {
    .description-line{
      display: flex;
      .label{
        width: 40%;
        text-align: end;
        margin-right: 2rem;
      }
      .value {
        width: 60%;
      }
    }
  }
}

@media screen and (max-width: $custom-width) {
  #retreat-data {
    .data-bloc {
      padding: 0 1rem;
    }
    .description-line {
      display: flex;
      margin-bottom: 1rem;

      .label {
        width: 40%;
      }
    }

    .row {
      flex-direction: column;
      .description-line {
        width: 100%;
        display: flex;
        .label {
          width: 40%;
        }
        .value {
          width: 60%;
        }
      }

      .col {
        width: 100%;
      }
    }
    .next-paiement {
      .description-line {
        .label {
          width: 60%;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  #retreat-data {
    .data-bloc {
      padding: 0 0.5rem;
    }
    .description-line {
      flex-direction: column;
      margin-bottom: 1rem;

      .label {
        width: 100%;
      }
    }

    .row {
      flex-direction: column;
      .description-line {
        width: 100%;
        display: flex;
        .label {
          width: 100%;
        }
        .value {
          width: 100%;
        }
      }
      .col {
        width: 100%;
      }
    }
    .next-paiement {
      .description-line {
        .label {
          width: 100%;
          text-align: left;
        }
        .value {
          width: 100%;

        }
      }
    }
  }
}
