#cgu-page {
  padding: 2rem 3rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .buttons {
    margin-top: 2rem;
    & > div:first-child {
      margin-right: 2rem;
    }
  }
}

.cgu-alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 2px $yellow-secondary;
  background-color: rgba(249, 179, 48, 0.09) ;
  padding: 2rem;
  margin-bottom: 2rem;
}

#step-3 .cgu-content .cgu-container {
  height: 30rem;
}

#cgu-page, #step-3 {
  .cgu-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .cgu-container {
      max-width: 100%;
    }

    .cgu-container-portail {
      width: 80vw;
      height: 100%;
    }

    .cgu-container, .cgu-container-portail {
      margin: 1rem;
      margin-bottom: 5rem;
      padding-right: 1rem;
      overflow-y: auto;
      text-align: justify;
    }

    .cgu-actions {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
      align-items: center;

      &:last-child {
        z-index: 2;
        margin-bottom: -57px;
      }
    }
  }
}

@media screen and (max-width: $desktop-width) {
  #step-3 .cgu-content .cgu-actions:last-child {
    margin-bottom: -62px;
    button {
      height: 3rem;
      padding: 0 1rem;
    }
  }
}

@media screen and (max-width: $touchpad-width) {
  #cgu-page {
    padding: 2rem 1rem;
    .buttons {
      flex-direction: column;
      & > div:first-child {
        margin: 0;
      }
    }
  }
  #step-3 .cgu-content .cgu-actions {
    flex-direction: column;
    width: 90%;
    margin: 1rem 1rem 0 1rem;
    &:last-child {
      margin-bottom: 0px;
      button {
        height: 2rem;
        // padding: 0 3rem;
      }
    }
  }
}
