.alert {
  z-index: 2;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 110px;
  left: -600px;
  width: auto;
  max-width: 440px;
  min-height: 4.5rem;
  padding: .7rem .2rem .2rem .5rem;
  background: white;
  box-shadow: 0 2px 5px -1px $gray7;

  .infos {
    width: 100%;
  }

  &.INFP {
    border: 1px solid $INFP;
  }

  &.ALER {
    border: 1px solid $ALER;
  }

  &.INFG {
    border: 1px solid $INFG;
  }

  p {
    margin-block-start: .3rem;
    margin-block-end: .7rem;
  }

  h2 {
    margin-block-end: .5rem;
  }

  &.isOpen {
    left: 30px;
    animation: bounceIn 0.4s;
    @media screen and (max-width: $custom-width) {
      animation: slideIn 0.4s;
    }
  }

  &.isClosed {
    left: -600px;
    animation: bounceOut 0.4s;
  }

  .header {
    position: absolute;
    right: 15px;
    flex-direction: row-reverse;

    .close {
      cursor: pointer;
      font-weight: bold;

      &.INFP {
        color: $INFP;
      }

      &.ALER {
        color: $ALER;
      }

      &.INFG {
        color: $INFG;
      }
    }
  }

  .infos {
    width: 100%;
    align-items: center;
    justify-items: center;
    display: flex;

    .icon {
      font-size: 32px;
      width: 50px;
      padding: 0 0.2rem;

      &.INFP {
        color: $INFP;

        i {
          transform: rotate(-45deg);
        }
      }

      &.ALER {
        color: $ALER;
      }

      &.INFG {
        color: $INFG;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: 0 .5rem;
      width: 100%;
      h2 {
        font-size: 1rem;
        &.INFP {
          color: $INFP;
        }

        &.ALER {
          color: $ALER;
        }

        &.INFG {
          color: $INFG;
        }
      }
    }
  }

  @media screen and (max-width: $touchpad-width) {
    margin-left: -20px;
    margin-right: 10px;
  }

}

@keyframes bounceIn {
  0% {
    left: -600px;
  }
  60% {
    left: 50px;
  }
  100% {
    left: 30px;
  }
}

@keyframes slideIn {
  0% {
    left: -600px;
  }
  100% {
    left: 30px;
  }
}

@keyframes bounceOut {
  0% {
    left: 30px;
  }
  100% {
    left: -600px;
  }
}
