.cartouche-idnot {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 250px;

  .menu {
    display: flex;
    height: 3rem;
    cursor: pointer;

    &:hover {
      background-color: $gray-lightest;
    }

    .avatar {
      border-radius: 50%;
      display: inline-block;
      height: 3rem;
      text-align: center;
      vertical-align: middle;
      width: 3rem;
    }

    .names {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 1rem;
      text-align: left;
      color: $blue-cobalt;

      & > span {
        line-height: 15px;
        margin-bottom: 3px;

        &:last-child {
          text-transform: uppercase;
        }
      }
    }

    .cartoucheIDNot-caret-style {
      border-right: 1px solid $blue-cobalt;
      color: $blue-cobalt;
      justify-content: center;
      align-self: center;
      padding-right: 1rem;
      height: 100%;
    }
  }

  .notaccess {
    margin-left: 1rem;

    img {
      cursor: pointer;
      background: url('../img/cartouche/th-solid.svg') no-repeat bottom;
      background-size: 90%;
      width: 30px;
      height: 25px;

      &:hover {
        background-color: $gray-lightest;
      }
    }
  }
}

.cartoucheIDNot-menu-account {
  color: black;
  text-decoration: none;
}

@media screen and (max-width: $touchpad-width) {
  .cartouche-idnot {
    width: auto;
    margin-right: 5px;

    .names {
      display: none !important;
    }

    .cartoucheIDNot-caret-style {
      display: none !important;
    }

    .notaccess {
      display: none;
    }
  }
}
