#pack-payment {
  .circle-container {
    position: relative;
    .ligne {
      background-color: $yellow-secondary;
      width: 80%;
      margin: 0 auto;
      height: 1px;
      position: absolute;
      top: 50%;
      z-index: 0;
    }
    .limit {
      height: 1px;
      opacity: 0.4;
      margin: 10px auto ;
      background-color: $yellow-secondary;
    }
  }
}
