.back {
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
}

.app-container {
  max-width: 1200px;
  justify-content: center;
  padding: 0 7rem 5rem;
  margin: 0 auto;

  .bloc-container {
    background-color: $gray1;
    border-radius: 2rem;
    max-width: 400px;
    padding: 1rem 3rem;
  }
}

@mixin brush($color) {
  color: white;
  @if $color == $yellow-secondary {
    background: url('../img/accueil/yellowBrush.png') no-repeat;
  } @else if $color == $blue-secondary {
    background: url('../img/accueil/blueBrush.png') no-repeat;
  } @else  {
    background: url('../img/accueil/cobaltBrush.png') no-repeat;
  }
  background-size: contain;
  span {
    background-color: $color;
    z-index: 1;
  }
}

.app-title {
  position: relative;
  height: 150px;
  width: 400px;
  display: flex;
  justify-content: center;

  &.yellow {
    @include brush($yellow-secondary);
  }
  &.blue {
    @include brush($blue-secondary);
  }
  &.cobalt {
    @include brush($blue-cobalt);
  }
  & * {
    font-size: 1.7em;
  }
  span {
    position: absolute;
    left: 160px;
    top: 43px;
    width: 180px;
    padding-left: 10px;
    color: white;
  }
  h1 {
    text-transform: uppercase;
    position: absolute;
    left: 120px;
    top: 40px;
    text-align: center;
    font-weight: lighter;
    color: white;
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    padding: 15px 40px 2px 8px;
  }

  h2 {
    position: absolute;
    left: 80px;
    top: 40px;
    font-size: 1.3rem;
    text-align: center;
    font-weight: lighter;
    color: white;
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    padding: 20px 13px 4px 12px; // gaodu, di xia xian chang du, di xia xian gao du, left
    //width: 250px;
  }
}

@media screen and (max-width: $big-screen-width) {
  .app-container {
    //  padding-left: 3rem;
    //  padding-right: 3rem;
  }
  .app-title {
    height: 130px;
    width: 300px;
    & * {
      font-size: 1.4em;
    }
    span {
      left: 100px;
      width: 175px;
      top: 32px;
    }
    h1 {
      left: 80px;
      top: 30px;
    }
    h2 {
      left: 60px;
      top: 20px;
      font-size: 1.12rem;
    }
  }
}

@media screen and(max-width: $desktop-width){
  .app-container {
    // padding-left: 3rem;
    // padding-right: 3rem;
  }
  .app-title {
    height: 130px;
    width: 300px;
      & * {
        font-size: 1.4em;
      }
      span {
        left: 100px;
        width: 175px;
        top: 35px;
      }
      h1 {
        left: 80px;
        top: 30px;
      }
      h2 {
        left: 60px;
        top: 23px;
      }
  }
}

@media screen and(max-width: $touchpad-width){
  .app-container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .app-title {
       height: 105px;
       width: 280px;
       & * {
         font-size: 1.2em;
       }
       span {
         left: 85px;
         width: 160px;
         top: 28px;
       }
       h1 {
         left: 70px;
         top: 25px;
       }
      h2 {
        left: 55px;
        top: 18px;
        font-size: 1.1em;
      }
  }
}

@media screen and(max-width: $custom-width){
  .app-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and(max-width: $mobile-width){
  .app-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .app-title {
    height: 136px; // 85
    width: 364px; // 240
    & * {
      font-size: 0.9em;
    }
    span {
      left: 90px;
      width: 225px;
      top: 40px;
      font-size: 1.4rem;
    }
    h1 {
      left: 80px;
      top: 30px;
      font-size: 1.4em;
      padding: 25px 15px 4px 15px;
      width: 50%;
      text-align: left;
    }
    h2 {
      left: 80px;
      top: 30px;
      font-size: 1.4em;
      padding: 25px 15px 4px 15px;
    }
  }
}
