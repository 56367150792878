$data-row-border-radius: 4px;

// 5 data columns for Encaissement + 1 column for the accordion icon should add up to 100%
$encaissement-column-flex-size: 18%;
$encaissement-empty-column-flex-size: 10%;
$encaissement-field-min-height-mobile: $spacer-8;

$conjoint-consent-tile-height: 12rem;

$infobulle-size: 1.5rem;

.data-container #cotisation-data {
  .dates, .item, .header-item {
    &.number {
      font-family: $font-family;
    }
  }

  .regime-header {
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: $cotisation-data-row-padding $cotisation-data-row-padding 1rem;

    .header-item {
      display: flex;
      justify-content: center;
      text-align: center;
      flex-basis: 20%;
    }
  }

  .content {
    padding-bottom: $spacer-9;

    .filter-text {
      font-size: $font-size-medium;
    }

    .filter-title {
      padding: $spacer-4 0;
      color: $blue-cobalt;
      text-transform: uppercase;
    }

    .filter-no-data {
      padding: 0 $spacer-4;
    }

    .filter-separator {
      // 2 rem = extra separator width from notairesFilter
      width: calc(100% - 2rem);
      margin: 0 0 $spacer-4 $spacer-4;
      border-bottom: 1px solid $gray-lighter;
    }

    .erreur {
      font-size: $font-size-medium;
      text-align: center;
    }

    .ligne-erreur {
      margin-bottom: $spacer-4;
    }
  }

  .regime-base, .regime-complementaire-b, .regime-complementaire-c, .regime-invalidite {
      .accordion-item-header {
        display: flex;
        width: 100%;

        .header-item {
          display: flex;
          flex-basis: 20%;
          justify-content: center;
          align-items: center;
          text-align: center;
          gap: $spacer-2;
        }
      }

    .moy {
      white-space: break-spaces;
    }
  }

  .regime-invalidite {
    .accordion-item-header {
      .header-item {
        flex-basis: 20%;

        &.last {
          flex-basis: 40%;
        }
      }
    }

    .ligne {
      display: flex;
      align-items: center;
      min-height: 40px;
      padding: $cotisation-data-row-padding 0;
      margin-bottom: $spacer-4;
      background-color: $accordion-bg;
      border-radius: $data-row-border-radius;
    }

    .regime-header {
      .header-item {
        flex-basis: 20%;

        &.last {
          flex-basis: 40%;
        }
      }
    }

    .accordion-item .accordion-item-header .dropdown-icon {
      flex-basis: 40%;
    }
  }

  .regime-complementaire-ccm {
    .ligne {
      display: flex;
      align-items: center;
      min-height: 40px;
      padding: $cotisation-data-row-padding;
      margin-bottom: $spacer-4;
      background-color: $accordion-bg;
      border-radius: $data-row-border-radius;
      font-weight: bold;
    }
  }

  .consent {
    .warn-tile {
      display: flex;
      gap: $spacer-2;
      justify-content: center;
      align-items: center;
      height: $conjoint-consent-tile-height;
      margin: $spacer-4 0;
      padding: $spacer-3 $spacer-4;
      background-color: $accordion-bg;
      border-radius: $data-row-border-radius;

      .title {
        text-align: center;
        padding-right: $spacer-4;
        font-size: $font-size-medium;
        color: $blue-cobalt;
        text-transform: uppercase;
      }
    }
  }

  .donnees-pro, .consent {
    position: relative;

    &::before {
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      height: 1px;
      content: "";
      opacity: 1;
      transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: rgba(0, 0, 0, 0.12);
    }

    .donnees-pro-content {
      display: flex;
      flex-direction: column;
      gap: $spacer-2;
      margin: $spacer-4 0;
      padding: $spacer-3 $spacer-4;
      background-color: $accordion-bg;
      border-radius: $data-row-border-radius;

      .title {
        margin-bottom: $spacer-2;
        font-size: $font-size-medium;
        color: $blue-cobalt;
        text-transform: uppercase;
      }

      .ligne {
        display: flex;
        gap: $spacer-4;

        .item {
          display: flex;
          flex-basis: 70%;

          &.category {
            align-items: center;
            gap: $spacer-2;
            flex-basis: 30%;
          }

          &.payment {
            display: flex;
            flex-direction: column;
            flex-basis: 70%;
          }

          &.blue-cobalt {
            color: $blue-cobalt;
          }
        }
      }
    }
  }

  .infobulle {
    display: flex;
    align-items: center;

    .icon {
      display: flex;
      justify-content: center;
      width: $infobulle-size;
      height: $infobulle-size;
      border-radius: 50%;
      background-color: $blue-cobalt;
      font-family: cursive;
      font-size: $font-size-normal;
      font-weight: 600;
      color: white;
    }
  }

  .cotisations {
    .header-item {
      flex-basis: 20%;
      text-align: center;
    }

    .accordion-item {
      .accordion-item-header {
        display: flex;
        width: 100%;
        font-weight: bold;

        .dropdown-icon {
          flex-basis: 80%;
        }

        .header-item {
          flex-basis: 20%;
          text-align: center;
        }
      }

      .accordion-item-content {
        .ligne {
          display: flex;
          gap: $spacer-2;
        }

        .item {
          flex-basis: 40%;

          &.blue-cobalt {
            color: $blue-cobalt;
          }
        }
      }
    }
  }

  .encaissement {
    .regime-header {
      .header-item {
        display: flex;
        justify-content: center;
        text-align: center;
        padding-right: $spacer-2;
      }
    }

    .header-item {
      flex-basis: $encaissement-column-flex-size;

      .ligne {
        display: flex;
        background-color: $accordion-bg;
        border-radius: $data-row-border-radius;
        padding: $cotisation-data-row-padding;
        margin-bottom: 1rem;
        min-height: 40px;
        align-items: center;
        font-weight: bold;

        .item {
          display: flex;
          justify-content: center;
          text-align: center;
          flex-basis: $encaissement-column-flex-size;
          padding-right: $spacer-2;
        }
      }
    }

    .empty-header {
      flex-basis: 10%;
    }

    .accordion-item .accordion-item-header {
      display: flex;
      width: 100%;

      .dropdown-icon {
        flex-basis: $encaissement-empty-column-flex-size;
      }

      .header-item {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-basis: $encaissement-column-flex-size;
        padding-right: $spacer-2;
      }
    }
  }
}

.regime-invalidite {
  .regime-content {
    .accordion-item-content {
      padding: $spacer-2;

      .subheader {
        color: $blue-cobalt;
      }

      .subheader, .ligne {
        .double-label {
          display: flex;
          flex-direction: column;
        }
        
        .item {
          flex-basis: 28%;
          padding: 0 $spacer-2;

          &.year {
            flex-basis: 16%;
          }
        }
      }
    }
  }

  .header-item {
    flex-basis: 13%;
  }

  .ligne {
    .item {
      flex-basis: 13%;
    }
  }
}

.regime-complementaire-ccm {
  .header-item {
    flex-basis: 20%;
  }

  .ligne {
    .item {
      display: flex;
      justify-content: center;
      flex-basis: 20%;
    }
  }
}

@media screen and (max-width: $touchpad-width) {
  .data-container #cotisation-data {
    .cotisations {
      .accordion-item .accordion-item-content .item.regime {
        flex-basis: 60%;
      }
    }

    .seperate-between-family {
      margin-top: $spacer-6;
    }
  }
}

@media screen and (max-width: $custom-width) {
  #cotisation-data {
    .encaissement {
      display: flex;
      flex-flow: row;
      margin-bottom: $spacer-4;

      .regime-header {
        flex-direction: column;
        flex-basis: 27%;
        grid-gap: $spacer-1;

        .header-item {
          flex-basis: unset;
          align-items: center;
          width: 100%;
          min-height: $encaissement-field-min-height-mobile;

          &.empty-header {
            display: none;
          }
        }
      }

      .regime-content {
        display: flex;
        flex-direction: row;
        flex-basis: 73%;

        .header-item {
          display: flex;
          flex-direction: column;
          flex-basis: 50%;

          .ligne {
            flex-direction: column;
            gap: $spacer-1;
            margin: 0 $spacer-2;

            .item {
              align-items: center;
              min-height: $encaissement-field-min-height-mobile;
              padding-right: 0;
            }

            .empty-header {
              display: none;
            }
          }
        }

        .accordion-item {
          display: flex;
          flex-direction: column;
          flex-basis: 50%;
          margin: 0 $spacer-2;
          background-color: unset;

          .accordion-item-header {
            position: relative;
            flex-direction: column;
            gap: $spacer-1;

            .header-item {
              min-height: $encaissement-field-min-height-mobile;
              padding-right: 0;
            }

            .dropdown-icon {
              position: absolute;
              bottom: $spacer-3;
            }
          }

          .accordion-item-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .subheader {
              justify-content: center;
              text-align: center;
              padding-top: unset;
            }
          }
        }
      }
    }

    .regime-base, .regime-complementaire-ccm {
      .regime-header {
        .header-item {
          flex-basis: 30%;
        }
      }

      .regime-content {
        .accordion-item-header {
          .header-item {
            flex-basis: 30%;
            text-align: center;
          }
          .dropdown-icon {
            flex-basis: 10%;
          }
        }
      }


      .accordion-item-content {
        display: flex;
        flex-flow: row;

        .ligne, .subheader {
          display: flex;
          flex-flow: column;
          flex-basis: 30%;

          .item {
            margin-bottom: $spacer-4;
          }
        }
      }
    }

    .regime-complementaire-b, .regime-complementaire-c {
      .regime-header .header-item{
        flex-basis: 30%;
      }

      .regime-content {
        .accordion-item-header {
          .header-item {
            flex-basis: 30%;
          }
          .dropdown-icon {
            flex-basis: 10%;
          }
        }
      }
    }

    .regime-complementaire-b .regime-content {
      .ligne, .subheader {
        // subheader has 1 dates element and 3 item elements (31 + 23 * 3 = 100)
        .dates {
          flex-basis: 31%
        }
        .item {
          flex-basis: 23%;
          text-align: center;
        }
      }
    }
    .regime-complementaire-ccm {
      .regime-content .ligne .item {
        flex-basis: 30%;
      }
    }

    .regime-invalidite {
      display: flex;
      flex-direction: column;

      .regime-content {
        flex-basis: 73%;

        .ligne {
          display: flex;
          flex-flow: row;
          flex-basis: 50%;

          .item {
            vertical-align: middle;
            min-height: 35px;
            margin-bottom: $spacer-1;
            line-height: 35px;
          }
        }

        .accordion-item {
          .accordion-item-header {
            .header-item {
              flex-basis: 20%;

              &.last {
                flex-basis: 40%;
              }
            }

            .dropdown-icon {
              flex-basis: 40%;
            }
          }
        }
      }

      .regime-header {
        flex-basis: 27%;

        .header-item {
          flex-basis: 30%;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .data-container #cotisation-data {
    .donnees-pro .donnees-pro-content .ligne {
      .item {
        flex-basis: 30%;

        &.category {
          flex-basis: 50%;
        }

        &.payment {
          flex-basis: 30%;
          word-break: break-all;
        }
      }
    }

    .regime-complementaire-b {
      .regime-header {
        .header-item {
          flex-basis: 35%;

          &.year {
            flex-basis: 20%;
          }
        }
      }

      .regime-content {
        .ligne, .subheader {
          // subheader has 1 dates element and 3 item elements (28 + 24 * 3 = 100)
          .dates {
            flex-basis: 28%
          }
          .item {
            flex-basis: 24%;
            text-align: center;
          }
        }
      }

      .accordion-item-header {
        .header-item {
          flex-basis: 35%;

          &.year {
            flex-basis: 20%;
          }
        }
      }
    }

    .regime-invalidite {
      .regime-header {
        gap: 0;

        .header-item {
          flex-basis: 20%;

          &.last {
            flex-basis: 40%;
          }
        }
      }

      .accordion-item-header {
        .header-item {
          flex-basis: 30%;
        }

        .dropdown-icon {
          flex-basis: 40%;
        }
      }
    }
  }
}