.identity-banner-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > h1, h2 {
    color: $gray-dark;
  }

  h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 20px;
  }

  @media screen and(max-width: $touchpad-width) {
    h1 {
      font-size: 30px;
      margin-bottom: 15px;
    }

    h2 {
      font-size: 20px;
    }
  }

  @media screen and(max-width: $mobile-width) {
    h1 {
      font-size: 20px;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 16px;
    }
  }
}

.enrolement-presentation {
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.enrolement-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .description {
    width: 50%;
    @media screen and(max-width: $desktop-width) {
      width: 59%;
    }
    @media screen and(max-width: $touchpad-width) {
      width: 90%;
    }
  }
}

