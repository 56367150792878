.service-container {
  background-color: $service-bg;
  border-top: solid 1px $yellow-secondary;
}

.file-container {
  background-color: $file-bg;
  border-top: solid 1px $blue-light;
}

.data-container, .delegation-container {
  background-color: $delegation-bg;
  border-top: solid 1px $blue-cobalt;
  padding-bottom: 3rem;

  #family-data, #professional-data, #personal-data, #professional-data-tier, #cotisation-data {
    display: flex;
    flex-direction: column;
    padding: 1rem 0 1rem 0;
    margin-top: 0;

    // TODO à modifier pour le css des champs
    //.field-value {
    //  background-color: white;
    //  padding: 0.7rem 1.2rem 0.7rem 1.2rem;
    //  border-radius: 3px;
    //  border: 2px solid $gray7;
    //  &.error {
    //    border: $red-danger 1px solid;
    //  }
    //  .tooltip {
    //    margin: auto auto auto 1rem;
    //  }
    //}

    .subTitle {
      margin-top: 2rem;
      margin-bottom: 0.5rem;
      font-size: 1.05rem;
      color: $blue-cobalt;
    }
    .render-row {
      display: flex;
      margin-bottom: 1rem;
      margin-left: 2rem;

      .value {
        margin-left: 1rem;
        text-align: left;
      }
    }

    .type-title {
      margin-left: 1rem;

      .value {
        font-weight: bold;
        margin-left: 2rem;
        color: $blue-cobalt;
      }
    }

    .date-title {
      font-size: 0.9rem;

      .value {
        margin-left: 1rem;
        text-align: left;
        font-size: 1.1rem;
      }
    }

    .asterisk {
      color: $blue-cobalt;

      .children-count {
        text-transform: uppercase;
      }
    }

    .calendar-icon {
      position: absolute;
      z-index: 1;
      margin: 10px 0 0 10px;
      font-size: 1.5rem;
    }
  }
}

.updatingText {
  color: $blue-cobalt;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  padding: 4px 10px;
  background-color: rgba(32, 77, 156, 0.1);
  border-radius: 12px;
  width: fit-content;
  margin: auto;
  margin-bottom: 3rem;
  i {
    margin-right: 4px;
  }
}

.service-container, .data-container, .file-container, .delegation-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  margin: 5rem 0 0;

  .page-container {
    width: 100%;
    position: relative;
    margin-top: -80px;

    & .bloc-container {
      width: 100%;
      display: flex;
      flex-direction: row;
    }

    .document-infos {
      display: flex;
      justify-content: center;
      font-weight: bold;
    }
  }

  .bloc-container {
    position: relative;
    justify-content: center;

    .navigate-button {
      position: absolute;
      z-index: 2;
      width: 916px;
      top: -1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .bloc {
      margin: 1rem;
      padding: 4rem;
      border: 2px solid black;
      background-color: $white;
      position: relative;
      min-width: 400px;
      height: fit-content;

      &.data {
        width: 916px;
      }

      .seperate-between {
        display: flex;
        flex-direction: row;
        height: 200px;
        justify-content: space-between;
        align-items: center;
        margin-top: -10px;
        margin-bottom: 20px;
      }

      .seperate-between-family {
        display: flex;
        flex-direction: row;
        height: 200px;
        justify-content: space-between;
        align-items: center;
        margin-top: -10px;
        margin-bottom: 40px;
      }

      .family-consult {
        position: relative;
        font-weight: bold;
        width: 70%;
        font-size: 28px;
      }

      .description-line {
        display: flex;
        flex-direction: row;
      }

      .label-value {
        position: relative;
        left: 30px;
      }

      .user-info-bloc {
        position: relative;
        width: 75%;
      }

      .professional-data-wrapper {
        .email-tier {
          &-label {
            color: $blue-cobalt;
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
          }

          &-input {
            display: flex;
            background-color: $white;
            width: 100%;
            padding: 0.7rem 1.2rem;
            border-radius: 3px;
            border: 2px solid $gray7;
            color: black;
            outline: none;
            font-size: 1.2rem;
            margin: 0.7rem 0 2rem 0;

            &::placeholder {
              color: black;
            }
          }
        }

        .offices {
          font-size: 1.1rem;
          margin-bottom: 2rem;

          &-label {
            color: $blue-cobalt;
            font-size: 1.2rem;
            line-height: 1.5rem;
            border-bottom: 1px solid $gray-lighter;
          }

          .office-wrapper {
            padding-top: 3rem;

            &-title {
              font-size: 1.2rem;
            }

            &-adresse {
              padding: 1rem 0;
            }

            &-delegations {
              color: $blue-cobalt;
              font-size: 1.2rem;
              padding-bottom: 1rem;
            }

            &-notaire {
              background-color: $gray-lightest;
              border-radius: 5px;
              padding: 1rem;
              display: flex;
              align-items: center;
              justify-content: space-between;

              &-numpers {
                font-size: 1rem;
              }

              &-enrol {
                text-align: end;
                color: $gray;
              }
            }
          }
        }
      }

      & > .title {
        position: absolute;
        max-width: 90%;
        width: fit-content;
        top: -1rem;
        left: 1rem;
        margin: 0;
        padding: 0 1rem;
        background: linear-gradient(to bottom, rgba(20, 10, 249, 0.00) 0, rgba(20, 10, 249, 0.00) 11px, white 11px, white 100%);
        white-space: nowrap;
      }

      .link {
        padding-bottom: 10px;
        margin-left: 1rem;
        font-size: 1rem;

        a {
          white-space: nowrap;
        }
      }

      .yellow {
        color: $yellow-secondary;
      }

      .blue {
        color: $blue-secondary;
      }

      .cobalt {
        color: $blue-cobalt;
      }
    }

    .--yellow {
      border-color: $yellow-secondary;

      &::before {
        background: $yellow-secondary;
      }

      &::after {
        background: $yellow-secondary;
      }

      & > .title {
        color: $yellow-secondary;
      }
    }

    .--blue {
      border-color: $blue-secondary;

      &::before {
        background: $blue-secondary;
      }

      &::after {
        background: $blue-secondary;
      }

      & > .title {
        color: $blue-secondary;
      }
    }

    .--cobalt {
      border-color: $blue-cobalt;

      &::before {
        background: $blue-cobalt;
      }

      &::after {
        background: $blue-cobalt;
      }

      & > .title {
        color: $blue-cobalt;
      }
    }

    &#dematerialisation {
      max-width: 1000px;
      margin: auto;

      .validation-button {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -2rem;
        button.disabled{
          border: solid 2px $yellow-secondary;
        }
      }
    }

    &#services-ris, &#retreat, &#retreat-request, &#career-rectification, &#contributor-certification, &#pack-fiscal, &#pack-payment, &#certificate-retirement {
      max-width: 800px;
      margin: auto;
    }
  }
}

.service-container .bloc-container .bloc {
  padding: 4rem 4rem;

  .link a {
    width: 500px !important;
  }
}

.save-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .no-modification-error-text {
    font-size: $font-size-normal;
    color: red;
  }
}

@media screen and (max-width: $big-screen-width) {
  .service-container, .data-container, .file-container, .delegation-container {
    .page-container {
      margin-top: -60px;

      .bloc-container {
        .navigate-button {
          width: 890px;
        }

        .bloc {
          min-width: 250px;
          padding: $spacer-7;

          &.data {
            width: 890px;
          }

          &::after {
            width: 30%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $desktop-width) {
  .service-container, .data-container, .file-container, .delegation-container {
    .page-container {
      .bloc-container {
        .navigate-button {
          width: 832px;
          top: -1.1rem;
        }

        .description-line {
          // padding-left: 3rem;
        }

        .bloc {
          min-width: 250px;
          margin: 1rem 0.3rem;
          padding: 3rem 1rem;

          &.data {
            width: 832px;
          }

          &::after {
            width: 20%;
          }
        }

        .seperate-between {
          height: 200px;
          margin-bottom: 0;
        }

        &#dematerialisation {
          margin: 0 1rem;
          width: auto;
        }
      }
    }
  }
}

@media screen and (max-width: $notepad-width) {
  .service-container, .data-container, .file-container, .delegation-container {
    .page-container {
      .bloc-container {
        .navigate-button {
          width: calc(100% - 2rem - 40px);
          top: -1.1rem;
          left: 40px;
        }

        .bloc.data {
          width: 720px;

          &.cotisation-data {
            width: calc(100% - #{$spacer-6})
          }
        }
      }

      .label-value {
        max-width: 520px;
      }
    }
  }
}

@media screen and (max-width: $touchpad-width) {
  .service-container, .data-container, .file-container, .delegation-container {
    .page-container {
      margin-top: -55px;

      .bloc-container {
        display: -ms-grid;
        display: grid;
        margin-top: 0;
        flex-direction: column;

        .navigate-button {
          display: none;
        }

        .bloc {
          min-width: 450px;
          margin: 1rem;
          padding: 2rem 1rem;

          &.data {
            width: calc(100% - 2rem);
          }
        }

        .family-consult {
          text-align: center;
        }

        .seperate-between {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 400px;
        }

        .seperate-between-family {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 200px;
        }

        .personal-logo {
          align-self: center;
        }

        .user-info-bloc {
          position: absolute;
          align-self: center;
          top: 180px;
          align-items: center;
        }

        &#dematerialisation {
          h3 {
            margin-top: 3rem;
          }
        }
      }

      .label-value {
        max-width: 400px;
      }
    }
  }
}

@media screen and (max-width: $custom-width) {
  .service-container, .data-container, .file-container, .delegation-container {
    .page-container {
      .description-line {
        padding-top: 10px;
      }

      .label-value {
        max-width: 200px;
      }

      .bloc-container {
        .bloc {
          min-width: 400px;

          & > .title {
            white-space: pre-wrap;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .service-container, .data-container, .file-container, .delegation-container {
    .page-container {
      margin-top: -70px;

      .bloc-container {
        &#dematerialisation {
          h3 {
            margin-top: 5rem;
          }

          .navigate-button {
            display: none;
          }
        }

        .bloc {
          min-width: 350px;
        }
      }

      .document-infos {
        text-align: center;
      }

      .description-line {
        padding-top: 10px;
      }

      .label-value {
        max-width: 150px;
      }

      .param-data {
        width: 30%;
      }
    }
  }
  .file-container {
    .app-title {
      span {
        width: 110px;
      }
    }
  }
}
