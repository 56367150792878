.tiles-connexion > .connexion-container ~ .connexion-container {
  margin-top: 3rem;
}

.tile-connexion, .connexion-container {
  width: 16rem;
  height: 9rem;
  display: flex;
  padding: .5rem;
  position: relative;
  align-items: center;

  .tile-text {
    font-size: 20px;
    line-height: 2.5rem;
    color: $blue-cobalt;
    width: 15rem;
    text-align: left;
    padding: 0.5rem;
  }

  .item-container {
    padding-left: 4rem;
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  @media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, & a { // IE11
      transform: translate(-50%);
    }
  }

  &.--actif, &.--tiersdeconfiance {
    .tooltip{
      color: $blue-cobalt;
    }
  }

  &.connexion-container {
    width: 100%;
    height: 18rem;
    border: $gray-lighter solid 2px;
    padding: 2rem;
    border-radius: 10px;
    margin: 0 1rem;
  }

  &.--retraite{
    .tooltip{
      color: $retraite;
    }
  }

  &.--beneficiaire{
    .tooltip{
      color: $beneficaire;
    }
  }

  &.--horsexercice{
    .tooltip{
      color: $horsexercice;
    }
  }

  &.disabled {
    border: $gray-light solid 2px;
    .tooltip{
      color: $gray-light;
    }

    .circle-img{
      border-radius: 0;
      background: url('../img/landing/disabled.svg') no-repeat center;
      background-size: contain;

      .jesuis{
        color: $gray-lighter;
      }
    }
  }

  .circle-img {
    display: flex;
    border-radius: 100%;
    height: 12rem;
    width: 12rem;

    &.--actif {
      background: url('../img/landing/actif.svg') no-repeat center;
      background-size: cover;
    }

    &.--tiersdeconfiance {
      background: url('../img/landing/delegataire.svg') no-repeat center;
      background-size: cover;
    }

    &.--retraite{
      background: url('../img/landing/retraite.svg') no-repeat center;
      background-size: cover;
    }

    &.--beneficiaire{
      background: url('../img/landing/beneficiaire.svg') no-repeat center;
      background-size: cover;
    }

    &.--horsexercice{
      background: url('../img/landing/horsExercice.svg') no-repeat center;
      background-size: cover;
    }

    .jesuis {
      .tooltip {
        position: absolute;
        // le tooltip mesure 110% du height de son wrapper
        top: calc(110% / 2);
        transform: translateY(-50%);
        right: -1rem;
        color: $white;

        span {
          background-color: $gray-dark;
          width: 18rem;
          height: 6rem;
          top: -80px;
          left: 165%;
          display: inline-flex;
          align-items: center;
          line-height: 1.2rem;
        }

        span:before {
          border-top: none;
        }

        span:after {
          // put the arrow down at the center of the tooltip
          right: calc((100%/2) - 20px/2);
          border-width: 20px 10px;
          border-color: $gray-dark transparent transparent transparent;
        }
      }

    }
    @media all and (-ms-high-contrast: none) {
      *::-ms-backdrop, & { // IE11
        transform: translate(-50%);
      }
    }

    @include jesuis(calc(50% - 22px))
  }
}

@media screen and (max-width: $desktop-width) {
  .tiles-connexion > .connexion-container ~ .connexion-container {
    margin-bottom: 3rem;
  }

  .tile-connexion {
    flex-shrink: 0;
    margin-bottom: 7rem;

    &:first-child {
      margin: 10rem 3rem 4rem;
    }

    &:last-child {
      margin-bottom: 10rem;
    }
  }

  .tiles-connexion > .connexion-container {
    box-sizing: content-box;
    width: 80%;
    height: 18rem;
    flex-direction: row;
    justify-content: center;
    padding: 0 1rem;

    .item-container {
      position: relative;
      left: 0;
      transform: none;
      padding-left: 1rem;
    }

    .tile-connexion {
      width: 12rem;
      margin: 0;
      padding: .5rem;
    }

    .btn-connect-container {
      position: absolute;
      top: 16rem;
      left: calc(50% - 8rem);
    }
  }
}

@media screen and (max-width: $touchpad-width) {
  .tile-connexion {
    flex-shrink: 0;
    margin-bottom: 7rem;

    &:first-child {
      margin: 10rem 3rem 4rem;
    }

    &:last-child {
      margin-bottom: 10rem;
    }
  }

  .tiles-connexion > .connexion-container {
    box-sizing: content-box;
    margin: auto;
    height: 46rem;
    flex-direction: column;
    margin-bottom: 10rem;
    justify-content: center;
    padding: 2rem 1rem;

    .item-container {
      display: flex;
      flex-direction: column;
      height: 38rem;
      justify-content: center;
      padding: 2rem 1rem 2rem 0;
    }

    .tile-connexion {
      height: 12rem;
      width: 12rem;
      margin: 0;
    }

    .btn-connect-container {
      position: absolute;
      top: 48rem;
      left: calc(50% - 8rem);
    }
  }
}