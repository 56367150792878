.delegation-container {
  .delegation-form {
    background-color: $white;
    border: solid 2px $blue-cobalt;
    border-radius: 0;
    padding: 4rem;

    .delegation-list-wrapper {
      margin-bottom: 40px;

      .pagination {
        margin-top: 30px;

        &-numbers {
          margin: 0 30px;
          display: flex;
          flex-direction: row;
          align-items: center;

          input {
            width: 2rem;
            text-align: center;
            border: $gray-lighter 1px solid;
            border-radius: 5px;
            padding: 5px 5px;
            font-family: $font-family;
          }

          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type=number] {
            -moz-appearance: textfield;
          }

          .separator {
            padding: 0 5px;
            transform: translateY(2px);
          }
        }


        i {
          padding: 0 10px;
          color: $blue_cobalt;
        }

        i:hover{
          cursor: pointer;
        }
      }
    }

    .centered-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-header {
      display: flex;
      padding-top: 24px;
      padding-bottom: 24px;

      &-icon {
        flex-basis: 30%;

        .circle-wrapper {
          background-color: $blue-cobalt;
          border-radius: 50%;
          height: 140px;
          width: 140px;

          i {
            color: $white;
          }
        }
      }

      &-text {
        flex-basis: 70%;
        display: flex;
        align-items: center;

        h2 {
          color: $blue-cobalt;
          height: fit-content;
        }
      }
    }

    &-separator {
      width: 100%;
      border: 1px solid $gray-lighter;
    }

    &-content {
      padding: 10px 0;

      h3 {
        color: $blue-cobalt;
      }

      .delegation-list-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        padding-right: 10px;

        h4 {
          margin-top: 0;
        }

        div {
          align-self: end;
        }

        &-content {
          .message-empty-list {
            margin-top: 25px;
          }
        }
      }
    }

    .delegate-item {
      border-radius: 12px;
      background-color: $gray-lightest;
      margin-bottom: 1.2rem;
      display: flex;
      padding: 10px 20px;

      &-name {
        flex-basis: 35%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
          color: $blue-cobalt;
          padding: 5px 0;
        }
      }

      &-infos {
        flex-basis: 65%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 2rem;

        .job {
          color: $blue-cobalt;
          text-transform: capitalize;
        }
      }

      &-action {
        flex-basis: 7%;
      }
    }
  }
}

@media screen and (max-width: $touchpad-width) {
  .delegation-container {
    padding: 0 4rem;

    .delegation-form-header {
      &-text {
        padding-left: 1rem;
      }
    }

    .delegation-form-content {
      .delegation-list-wrapper {
        .delegation-list-header {
          flex-flow: column;

          h4 {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .delegation-container {
    padding: 0;

    .delegation-form {
      padding: 1.2rem;

      .delegation-form-header {
        flex-flow: column;
        padding-top: 0;

        &-text {
          text-align: center;
        }
      }

      .delegation-list-header {
        max-height: fit-content;
        margin-bottom: 1rem;

        div {
          align-self: flex-start
        }

        &-content .delegate-item{
          flex-flow: column;

          .delegate-item-name {
            margin-bottom: 1rem;
          }

          .delegate-item-infos {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}