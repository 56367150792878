$button-height: 2.5rem;

@mixin button-container() {
  button {
    margin-right: 5px;
    font-family: $font-family;

    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin color($color) {
  &.--#{$color} {
    background-color: map-get($colors, $color);
    &:hover {
        background-color: darken(map-get($colors, $color), 10%);
    }
  }
}

.button-container {
  @include button-container();
}

.month-button {
  button {
    color: #F9B330;
    border: 1px solid;
    padding: 0 1rem;
    border-radius: 2rem;
    margin: 1rem 0;
    max-width: 54px;
    height: 2rem;
    font-family: $font-family;
    background-color: white;
    transition: .3s ease-in-out;

    &:hover {
      color: white;
      background-color: darken(#F9B330, 10%);
    }

    &:focus {
      outline: none;
    }

    &:not(.selected) {
      border-color: $gray-lighter!important;
      color: $gray-lighter;
      background-color: white;

      &:hover {
        color: white;
        background-color: darken(#F9B330, 10%);
      }
    }
  }
}

.year-button {
  button {
    font-size: 15px;
    color: white;
    border: 1px solid;
    padding: 0 2rem;
    border-radius: 2rem;
    margin: 1rem auto;
    height: 2rem;
    font-family: $font-family;
    transition: .3s ease-in-out;
    @include color("yellow");

    &:focus {
      outline: none;
    }

    &:not(.selected) {
      color: #F9B330;
      background-color: white;

      &:hover {
        color: white;
        background-color: darken(#F9B330, 10%);
      }
    }
  }
}

.btn-custom {
  display: flex;

  button {
    height: $button-height;
    margin: $spacer-4 auto;
    padding: 0 $spacer-7;
    border: none;
    border-radius: $border-radius-4;
    font-family: $font-family;
    color: $white;
    transition: .3s ease-in-out;
    font-size: $font-size-medium;
    @include color("yellow");
    @include color("blue");
    @include color("cobalt");

    &:focus {
      outline: none;
    }

    &.disabled {
      pointer-events: none;
      cursor: default;
      background-color: $gray5;
      color: rgba(0, 0, 0, 0.26);
    }
  }

  .save-button-placement {
    margin-bottom: -15px;
  }
}

.ghost-btn {
  display: flex;
  align-items: center;

  button {
    color: $blue-cobalt;
    background-color: white!important;
    border: 1px $blue-cobalt solid;
    padding: .3rem 1rem;
    border-radius: 2rem;
    margin: .3rem auto;
    font-family: $font-family;
    transition: .3s ease-in-out;

    &:hover {
      background-color: $blue-cobalt!important;
      color: $white;
    }

    &.--INFG {
      border: 1px $INFG solid;
      color: $INFG;
      &:hover {
        background-color: $INFG !important;
        color: $white;
      }
    }

    &.--INFP {
      border: 1px $INFP solid;
      color: $INFP;
      &:hover {
        background-color: $INFP!important;
        color: $white;
      }
    }

    &.--ALER {
      border: 1px $ALER solid;
      color: $ALER;
      &:hover {
        background-color: $ALER!important;
        color: $white;
      }
    }
  }
}

#file-detail, #services-ris, #retreat, #retreat-request, #career-rectification, #contributor-certification, #pack-payment, #pack-fiscal, #certificate-retirement {
  .btn-custom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    font-size: 18px;
    & > button {
      margin: 0 10px -15px 10px;
    }
  }
}

.btn-connect-container {
  margin: 0 auto;
  width: 16rem;
  height: 4rem;
  position: absolute;
  top: 15.75rem;
  left: calc(50% - 16rem / 2);

  &.modal-button-connect {
    top: auto;
    position: relative;
    padding: auto;
    margin: 20px auto 0 auto;

    span {
      display: none;
    }
  }

  .btn-idnot {
    cursor: pointer;
    display: flex;
    border: 2px solid $idnot-color;
    border-radius: 10px;
    background: white;
    align-items: center;
    height: 100%;
    padding: 5px;

    &:hover {
      background-color: $gray-lightest;
      text-decoration: none;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    .logo {
      height: 100%;
      width: 30%;
      background: url("../img/logo-idnot.svg") no-repeat center;
      background-size: contain;
    }

    .text-container {
      display: flex;
      flex-flow: column;
      align-items: baseline;
    }

    .text {
      color: $idnot-color;
      text-align: left;
      padding: 0 5px;
    }
  }

  &.btn-franceconnect-container {
    .btn-franceconnect-button {
      background: url("../img/france_connect.svg") no-repeat center;
      background-size: contain;
      height: 100%;
      display: block;
    }

    .btn-franceconnect-question {
      margin-top: 10px;
      display: inline-block;
      background: url('../img/icon-help.png') no-repeat left top;
      text-decoration: none;

      span {
        font-size: small;
        margin-left: 10px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, lighten($blue-cobalt, 30%) 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10,10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0,0);
    opacity: .2;
    transition: 0s;
  }
}

@media screen and (max-width: $desktop-width) {
  .month-button {
    button {
      min-width: 120px;
    }
  }
}

@media screen and (max-width: $custom-width) {
  #certificate-retirement {
    .btn-custom {
      & > button {
        height: 3rem;
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  #retreat-request {
    .btn-custom {
      & > button {
        height: 3rem;
      }
    }
  }
}
