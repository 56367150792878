#pack-fiscal {
  .row {
   display: flex;
   width: 100%;

   .bloc {
     min-height: 15rem;
     padding: 1.5rem;
     min-width: unset;
     width: 50%;
     .colonne-label {
       font-weight: 600;
       margin-bottom: 10px;
     }
     .limit {
       height: 1px;
       margin: 10px auto ;
       background-color: $yellow-secondary;
     }

     .ligne {
       display: flex;
       .col-left {
         width: 50%;
       }
     }

     &:first-child {
       margin-left: 0;
     }

     &:last-child {
       margin-right: 0;
     }
   }
 }
}

@media screen and (max-width: $touchpad-width) {
  #pack-fiscal {
    .row {
      margin: unset;
      display: flex;
      flex-direction: column-reverse;
      min-width: unset;

      .bloc {
        min-height: 10rem;
        margin: unset;
        width: 100%;
      }
    }
  }

}
