#app {
  margin: 0;

  & > .admin-container {
    min-height: 100vh;

    display: flex;
    flex-direction: column;

    & > .admin-content-container,
    & > header {
      display: flex;
      flex-direction: row;

      & > #aside-nav,
      & > #site-logo {
        width: $left-menu-nav-width;
        background: $gray-darker;
        color: $gray-lightest;

        & > .nav-item {
          background: $gray-darker;
          color: $gray-lightest;
        }
      }

      & > #header-nav,
      & > main {
        flex: 1 0 auto;
      }

      & > main {
        flex-basis: 0;
        width: 0; /* to make sure the main container will never be larger than it should */
        overflow: auto;
      }
    }

    & > .admin-content-container {
      flex: 1 0 auto;
    }
  }
}

/* Hack IE :( */
@media all and (-ms-high-contrast: none) {
  #app & > .admin-container {
    height: 100vh;
  }
}
