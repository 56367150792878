.form-container {
  position: relative;
  border: 2px solid;
  padding: 2rem 3rem;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  &.blue {
    border-color: $blue-cobalt;
    .title {
      color: $blue-cobalt;
    }
  }
  &.yellow {
    border-color: $yellow-secondary;
    .title {
      color: $yellow-secondary;
    }
  }
  .title {
    position: absolute;
    padding: 0 1rem;
    margin: 0;
    top: -20px;
    left: 30px;
    background-color: white;
  }
}
// new form management cf personnal data

.form, .delegate-item-action {
  .field {
    margin-top: 1.2rem;

    .input{
      .disabled{
        background-color:$gray-lighter;
        color: $gray-darker;
      }
      .error-field{
        .error-message{
          color: red;

        }
      }
    }
    .yearPicker {
      background-color: #ffffff;
      border-radius: 3px;
      border: 2px solid #A5A5A5;
      outline: none;
      input {
        padding: 0.7rem 1.2rem 0.7rem 1.2rem;

        font-size: 14px;
        border: unset;
      }
    }

  }

  .limit {
    height: 1px;
    opacity: 0.4;
    margin: 10px auto ;
    background-color: rgba(32, 77, 156, 0.4);
  }

  .custom-input {
    display: flex;
    background-color: $white;
    width: 100%;
    padding: 0.7rem 1.2rem 0.7rem 1.2rem;
    border-radius: 3px;
    border: 2px solid #A5A5A5;
    color: black;
    outline: none;
    font-size: 1.2rem;
    margin-top: 0.7rem;
    &::placeholder {
      color: black;
    }
    border-bottom: 1px solid $gray-light;
  }
  .select {
    display: flex;
    background-color: $white;
    width: 100%;
    height:45px;
    border-radius: 2px;
    border: 2px solid #A5A5A5;
    border-bottom: 1px solid;
    color: black;
    outline: none;
    font-size: 1.2rem;
    margin-top: 0.7rem;
    &::placeholder {
      color: black;
    }
    padding: 0.7rem 1.2rem 0.7rem 1.2rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .datepicker {
    margin-left:30px;
  }

  .yearPicker {
    .field {
      height: 3rem;
      border: 1px solid rgba(129,138,145, .5);
      border-radius: 3px;
      padding: 0.7rem 1.2rem 0.7rem 1.2rem;
      .material-input-coreoz{
        border: unset;
      }
      .icon {
        margin: unset;
      }
      input {
        padding-top: 3px;
        font-size: 16px;
        border: unset;
      }
      .material-container-input-coreoz {
        .material-input-coreoz {
          border-bottom: unset;
        }
      }
    }
  }

  .datePicker {
    .field {
      height: 3rem;
      border: 1px solid rgba(129,138,145, .5);
      border-radius: 3px;
      padding: 0.7rem 1.2rem 0.7rem 1.2rem;
      .material-input-coreoz{
        border: unset;
      }
      .icon {
        margin: unset;
      }
      input {
        padding-top: 3px;
        font-size: 16px;
        border: unset;
      }
      .material-container-input-coreoz {
        .material-input-coreoz {
          border-bottom: unset;
        }
      }
    }
  }
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-top:0.7rem;

    .cursor-disabled {
      cursor: not-allowed;
    }
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    &.small {
      width: 48px;
      height: 23px;
    }
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    margin: 4px;

    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .small {
    .slider:before {
      position: absolute;
      content: "";

      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;

      margin: 3px;
      height: 17px;
      width: 17px;
    }
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}


.form {
  border-radius: 20px;
  box-sizing: border-box!important;
  p {
    box-sizing: border-box!important;
    margin: 0;
  }
  &.size-1 {
    width: 400px;
  }
  &.size-2 {
    max-width: 800px;
  }
  &.size-3 {
    width: 80%;
  }

  .asterisk {
    font-size: 1.1rem;
    color: $blue-cobalt;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
      &.size-1 {
        width: 100%;
      }
      &.size-2 {
        width: 47%;
      }
      & label {
        & > div.field {
          display: flex;
          background-color: $white;
          &.field-title {
            // padding: 0.7rem 1.2rem 0.2rem 0rem;
            height: 22px;
            border-radius: 15px 15px 0 0;
          }
          &.field-value {
            padding: 0.7rem 1.2rem 0.7rem 1.2rem;
            border-radius: 3px;
            border: 2px solid $gray7;
            &.error {
              border: $red-danger 1px solid;
            }
            .tooltip {
              margin: auto auto auto 1rem;
            }
          }
          & > input, & > select {
            width: 100%;
            border: none;
            color: black;
            outline: none;
            &::placeholder {
              color: black;
            }
            &:not(.character):not(.number) {
              border-bottom: 1px solid $gray-light;
            }
          }
        }
        & > div.error-message {
          margin-top: 0.5rem;
          padding: 0 1.2rem;
        }
        &.disabled {
          pointer-events: none;
          & > div.field {
            opacity: 0.3;
          }
        }
      }
      & .asterisk:not(.legend) {
        // font-size: 1.2rem; // retours recette lot 2: unification des tailles de polices
        &::after {
          content: " *";
          color: red;
          font-size: 1.2rem;
        }
      }
      & input {
        font-size: 1.4rem;
      }
      & select {
        font-size: 1.2rem;
      }
      & .react-datepicker-wrapper, & .react-datepicker__input-container {
        display: block;
        & .datepicker {
          cursor: pointer;
          border: none;
          border-bottom: 2px solid $gray-lighter;
          width: 90%;
          padding-left: 2.5rem;
          outline: none;
        }
      }
      & .form-description {
        display: flex;
        justify-content: space-between;
        & span {
          flex-basis: 40%;
        }
      }

      & .default-address-format {
        margin-top: 2rem;
      }

      & .no-justify-content {
        justify-content: normal !important;
      }

      & .subtitle {
        font-size: 16px;
      }
      & .description-subtitle {
        font-size: 14px;
      }
      & .icon {
        position: absolute;
        z-index: 1;
        margin: 3px 0 0 6px;
        font-size: 1.5rem;
      }
      & .form-icon {
        width: 89% !important;
        margin-left: 3rem;
      }
      & .react-datepicker-popper {
        z-index: 2;
      }
      & .react-datepicker__header {
        padding-bottom: 8px;
      }
      & .react-datepicker__month-text {
        padding-top: 12px;
        height: 36px;
      }
      & .react-datepicker__header__dropdown {
        padding: 8px;
      }

      // Modification specifique  à la page "mes-données-familiales"
      & .subtitle-family {
        font-size: 18px;
        font-weight: bold;
        color: $blue-cobalt;
      }
      & .form-button {
        background-color: $blue-cobalt;
        color: white;
        border: none;
        padding: 0 2rem;
        border-radius: 2rem;
        height: 2rem;
        font-family: $font-family;
        padding-top: 4px;
        margin: auto;
        margin-right: 0;

        i {
          margin-right:1rem;
        }
      }

      & .text-container {
        display: flex;
      }

      & .legend {
        position: absolute;
        right: 0;
        bottom: $spacer-4;
        margin: $spacer-4;
        &.asterisk::before {
          content: "* ";
          color: red;
          font-size: 1.2rem;
        }
      }
      &.offset {
        margin-left: 2rem;
      }

      .large-data {
        & p {
          flex-basis: 35%;
        }
        & span {
          flex-basis: 65% !important;
        }
      }

      .column{
        display: block;
      }

      .no-wrap {
        white-space: nowrap;
      }
    }
  }
  .btn-custom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    & > button {
      font-size: 16px;
      margin: 0 0 -15px 0;
    }
  }
  .important {
    font-weight: bold;
  }
}

.checkbox-container {
  display: block;
  font-size: 1.2rem;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  & > .checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  & > .checkmark {
    position: absolute;
    border-radius: 3px;
    border: 1px solid black;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6.3px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid $green-success;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  &:hover .checkbox ~ .checkmark {
    background-color: $gray-lighter;
  }
  & .checkbox:checked ~ .checkmark {
    &:after {
      display: block;
    }
  }
  & > .link {
    position: absolute;
    margin-left: 5px;
    display: contents;
  }
}

.radio-container {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: left;
  min-height: 25px;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1rem;
  & > .radio {
    z-index: 1;
    position: absolute;
    left: 0;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;

    &.--disabled {
      cursor: not-allowed;
      color: $gray;
    }

    &:checked ~ .radiomark {
      &:after {
        display: block;
      }
    }
  }
  & > .radiomark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid $blue-cobalt;
    border-radius: 50%;
    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 2.2px;
      left: 3.0px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: $blue-cobalt;
    }

    &.--disabled {
      border: 2px solid $gray;

      &:after {
        background: $gray;
      }
    }
  }
  & > * {
    font-size: 1.1rem;
  }
  & > strong {
    padding-left: 1rem;
  }
}

@media screen and (max-width: $desktop-width) {
  .form {
    &.size-2 {
      padding: 2rem 3rem;
    }
    .row div {
      &.size-1 {
        width: 100%;
      }
      &.size-2 {
        width: 48%;
      }
    }
  }
}

@media screen and (max-width: $touchpad-width) {
  .form-container {
    padding: 2rem 1rem;
  }
  .form {
    margin-left: auto;
    margin-right: auto;
    &.size-1 {
      // padding: 2rem 2rem;
      width: 100%;
    }
    &.size-2 {
      padding: 2rem 1rem;
      width: auto;
    }
    .row div {
      &.size-2 {
        width: 100%;
      }
      span {
        // font-size: 1rem;
      }
      input {
        font-size: 1.3rem;
      }
      & .form-icon {
        width: 85% !important;
      }
      .form-description > span {
        flex-basis: 40%;
      }
      & .radio-container .radiomark::after {
        top: 2.3px !important;
        left: 2.4px !important;
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .form {
    margin-left: 0;
    margin-right: 0;
    &.size-1 {
      padding: 1rem 1rem;
    }
    &.size-2 {
      padding: 1rem 1rem;
    }
    span {
      // font-size: 1rem;
    }
    input {
      font-size: 1.2rem;
    }
    & .form-icon {
      width: 80% !important;
    }
  }
}
