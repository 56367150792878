.tag {
  display: flex;
  background-color: #eee;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-right: 15px;
  position: relative;
  width: fit-content;
  border-radius: 5px;
  padding: 5px 5px 5px 7px;
  .button {
    margin: 0 0 0 5px;
  }
}