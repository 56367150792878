.error-page {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  button {
    color: white;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
