#professional-data, #professional-data-tier {
  .seperate-between-family{
    margin-bottom: 15px;
  }

  .limit {
    width: 100%;
  }


  .form {
    width: unset;
    padding: unset;

    .description {
    }

    .form-description {
      .label {
        margin-bottom: 5px;
      }
    }

    .activities {
      .row {
        // margin: 1rem 2.5rem;
        padding: 1.5rem;
        border-radius: 5px;
        background-color: rgba(32, 77, 156, 0.1);

      }
    }
    .row{
      .libelle {
      }
      .date {
        width: 50%;
        font-weight: 400;

        .form-description {
          justify-content: unset;
          .value{
            margin-left: 1rem;
          }

        }
      }
    }
  }

  .description-line {
    display: block;
    margin-bottom: 1.5rem;

    .label {
      margin-bottom: 8px;
      font-weight: 600;
      color: $blue-cobalt;

    }
    .value {

    }
  }
}


// family-consult
@media screen and (max-width: $touchpad-width) {
  #professional-data {
    .family-consult {
      margin-top: 1rem;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  #professional-data{
    .form .activities .row {
      padding: 5px;
      margin: 1rem 0;
      .form-description {
        flex-basis: 100%;
      }
      .date {
        width: 100%
      }
    }
  }
}