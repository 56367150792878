.cards-group {
  .card {
    display: inline-block;
    margin-right: 1rem;
    text-align: center;
    width: 12rem;

    .thumbnail-container {
      height: 10rem;

      >img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .card-block .card-text {
      font-weight: bold;
    }
   
    &.inactive {
      color: #ff0000;
    }
  }
}
