.popup {
  z-index: 3;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}

.popup_inner {
  position: absolute;
  width: 40%;
  height: 30%;
  left: 5%;
  right: 5%;
  top: 3rem;
  bottom: -5%;
  margin: auto;
  background: white;
  padding: 0 2rem;

  border: 2px solid $yellow-secondary;

  .close-icon {
    position: absolute;
    top: 5px;
    right: 20px;
    flex-direction: row-reverse;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    color: $gray;
  }

  .table {
    width: 80%;
    margin: auto;
    .evenly-row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
    .col {
      text-align: left;
      width: 20%;
    }
    .data {
      .limit {
        background: black;
      }
    }
  }



  .limit {
    height: 1px;
    width: 100%;
    background-color: $yellow-secondary;
  }

  p {
    text-align: center;
    font-size: 16px;
    position: relative;
    top: 10px;
  }
}

.month-bloc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.row-bloc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .container-circle {
    position: relative;
    border: 1px solid $yellow-secondary;
    height: 176px;
    width: 176px;
    border-radius: 80px;
    background-color: white;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -1px;
      background-color: white;
      width: 50%;
      height: 50%;
      border: 1px solid white;
    }
    &::after {
      content: '';
      position: absolute;
      top: -2px;
      left: 50%;
      width: 50%;
      height: 50%;
      background-color: white;
      border: 1px solid white;

    }
  }

  .circle {
    height: 160px;
    width: 160px;
    border-radius: 88px;
    background-color: $gray-lighter;
    position: absolute;
    top: 50%;
    left: 50%;
    transform:  translate(-50%, -50%);
    z-index: 1;
    &:hover {
      cursor: pointer;
    }

    &.current {
      background-color: $blue-cobalt;
      color: white;
    }

    .circle-text {
      position: relative;
      top: 40%;
      left: 50%;
      text-align: center;
      vertical-align: middle;
      transform: translate(-50%, -50%);

      .title {
        font-weight: bold;
      }
    }
  }
}

.year-bloc {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media screen and (max-width: $big-screen-width) {
  .popup_inner {
    width: 60%;
  }
}

@media screen and (max-width: $desktop-width) {
  .popup_inner {
    width: 70%;
    .table {
      width: 90%;
    }
  }

  .row-bloc {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media screen and (max-width: $notepad-width) {
  .popup_inner {
    width: 80%;
    .table {
      width: 95%;
    }
  }
}

@media screen and (max-width: $touchpad-width) {
  .row-bloc {
    width: auto;
    .table {
      .col {
        width: 25%;
      }
    }
  }
}

@media screen and (max-width: $custom-width) {
  .popup_inner {
    width: 90%;
    padding: 0 1rem;
  }

  .row-bloc {
    .container-circle {
      position: relative;
      border: 1px solid $yellow-secondary;
      height: 132px;
      width: 132px;
      border-radius: 66px;
    }

    .circle {
      height: 120px;
      width: 120px;
      border-radius: 60px;
    }
    .table {
      .col {
        width: 33%;
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .popup_inner {
    padding: unset;
    width: 90%;
    height: 60%;
    .table {
      width: 100%;
      .evenly-row {
        padding: 0 10px;
      }
      .col {
        width: 35%;
      }
      .col:last-child {
        width: 30%;
      }
    }
  }

  .row-bloc {
    padding-left: 0;
    padding-right: 0;
    .container-circle {
      height: 88px;
      width: 88px;
      border-radius: 44px;
    }

    .circle {
      height: 80px;
      width: 80px;
      border-radius: 40px;
      .circle-text {
        .title {
          font-size: 10px;
        }
        p {
          font-size: 10px;
        }
      }
    }
  }
}
