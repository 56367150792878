#fconnect-profile {
  margin-right: 2rem;
  & > a {
    &.link {
      color: $blue-cobalt;
    }
  }
}

@media screen and (max-width: $touchpad-width) {
  #fconnect-profile {
    visibility: hidden;
  }
}
