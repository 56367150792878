.watermark {
  position: absolute;
  top: 10px;
  left: 40px;
  height: 30px;
  width: 400px;
  background-color: red;

  span {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: black;
    font-size: 18px;
    font-weight: bold;
  }

  @media screen and (max-width: $desktop-width) {
    width: 300px;
  }
}
