// MARGIN
.m-auto {
  margin: auto;
}
@for $i from 0 through 5 {
  .m-#{$i} {
    margin: #{$i}rem;
  }
}
.mt-auto {
  margin-top: auto;
}
.mr-auto {
  margin-right: auto;
}
.mb-auto {
  margin-bottom: auto!important;
}
.ml-auto {
  margin-left: auto;
}
@for $i from 0 through 5 {
  .mt-#{$i} {
    margin-top: #{$i}rem!important;
  }
}
@for $i from 0 through 5 {
  .mr-#{$i} {
    margin-right: #{$i}rem;
  }
}
@for $i from 0 through 5 {
  .mb-#{$i} {
    margin-bottom: #{$i}rem!important;
  }
}
@for $i from 0 through 5 {
  .ml-#{$i} {
    margin-left: #{$i}rem;
  }
}
@for $i from 0 through 5 {
  .mt--#{$i} {
    margin-top: -#{$i}rem;
  }
}
@for $i from 0 through 5 {
  .mr--#{$i} {
    margin-right: -#{$i}rem;
  }
}
@for $i from 0 through 5 {
  .mb--#{$i} {
    margin-bottom: -#{$i}rem;
  }
}
@for $i from 0 through 5 {
  .ml--#{$i} {
    margin-left: -#{$i}rem;
  }
}

// PADDING
@for $i from 0 through 5 {
  .p-#{$i} {
    padding: #{$i}rem!important;
  }
}
@for $i from 0 through 5 {
  .pt-#{$i} {
    padding-top: #{$i}rem;
  }
}
@for $i from 0 through 5 {
  .pr-#{$i} {
    padding-right: #{$i}rem!important;
  }
}
@for $i from 0 through 5 {
  .pb-#{$i} {
    padding-bottom: #{$i}rem;
  }
}
@for $i from 0 through 5 {
  .pl-#{$i} {
    padding-left: #{$i}rem!important;
  }
}
@for $i from 0 through 5 {
  .pt--#{$i} {
    padding-top: -#{$i}rem;
  }
}
@for $i from 0 through 5 {
  .pr--#{$i} {
    padding-right: -#{$i}rem!important;
  }
}
@for $i from 0 through 5 {
  .pb--#{$i} {
    padding-bottom: -#{$i}rem;
  }
}
@for $i from 0 through 5 {
  .pl--#{$i} {
    padding-left: -#{$i}rem;
  }
}


// WIDTH
.w-auto {
  width: auto!important;
}
@for $i from 0 through 5 {
  .minw-#{$i} {
    min-width: #{$i * 50}px;
  }
}
@for $i from 1 through 10 {
  .w-#{$i * 10} {
    width: percentage($i / 10);
  }
}

// TEXT
.text-bold {
  font-weight: bold;
}
.text-italic {
  font-style: italic;
}
.text-uppercase {
  text-transform: uppercase!important;
}
.text-underline {
  text-decoration: underline;
}
.text-justify {
  text-align: justify;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-large-size {
  font-size: large;
}
.color-red {
  color: red;
}
@for $i from 0 through 5 {
  .lh-#{$i} {
    line-height: #{$i}rem;
  }
}
@for $i from 1 through 30 {
  .fs-#{$i} {
    font-size: #{$i}px!important;
  }
}

// LAYOUT
.d-flex {
  display: flex!important;
}
.d-none {
  display: none!important;
}
.d-block {
  display: block!important;
}
.d-inline {
  display: inline!important;
}
.d-inline-flex {
  display: inline-flex!important;
}
.d-contents {
  display: contents!important;
}
.p-absolute {
  position: absolute!important;
}
.p-relative {
  position: relative!important;
}
.p-fixed {
  position: fixed!important;
}
.p-sticky {
  position: sticky!important;
}

.justify-content-between {
  justify-content: space-between;
}
.justify-content-start {
  justify-content: start;
}
.justify-content-end {
  justify-content: end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-around {
  justify-content: space-around;
}

.align-content-center {
  align-content: center;
}

// FLEX
.f-dir-col {
  flex-direction: column!important;
}
.f-dir-row {
  flex-direction: row!important;
}

// DIVERS
@for $i from 0 through 5 {
  .zi-#{$i} {
    z-index: #{$i};
  }
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-initial {
  cursor: initial!important;
}
.cursor-not-allowed {
  cursor: not-allowed!important;
}

a {
  &.hover {
    text-decoration: none;
    padding: 5px 0;
    position: relative;

    &:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      position: absolute;
      transition: all 0.2s ease-in-out;
      transition-duration: 0.50s;
      width: 0;
    }

    &-yellow {
      &:after {
        background: $yellow-secondary;
      }

      &:hover, &:active {
        color: darken($yellow-secondary, 15%);
      }
    }

    &-blue {
      &:after {
        background: $blue-secondary;
      }

      &:hover {
        color: darken($blue-secondary, 15%);
      }
    }

    &-cobalt {
      &:after {
        background: $blue-cobalt;
      }

      &:hover {
        color: darken($blue-cobalt, 15%);
      }
    }

    &:hover {
      &:hover:after {
        width: 100%;
        left: 0;
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
