#footer {
  z-index: 1;
  background-color: $gray-dark;
  display: flex;

  div{
    display: flex;
    width: $width;
    color: $white;
    padding: 1rem 2rem;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  a{
    color: $white;
    text-decoration: none;
    padding: 0 2.9rem;
    border-right: 1px solid white;

    &:first-child {
      justify-self: start;
    }

    &:last-child {
      justify-self: end;
      border-right: none;
    }
  }
}

@media screen and (max-width: $width) {
  #footer {
    a {
      padding: 0 1.3rem;
    }
  }
}

@media screen and (max-width: $touchpad-width) {
  #footer {
    font-size: 0.8rem;
    position: unset;
    div {
      padding: 1rem 1rem;
    }
    a {
      padding: 0 1rem;
    }
  }
}

@media screen and (max-width: $custom-width) {
  #footer {
    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 1rem 0.5rem;
    }
  }
}
