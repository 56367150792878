html {
  font-size: $base-font-size;
  font-family: $font-family;
  height: 100%;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

input {
  margin: 0;
}

.radiomark {
  box-sizing: content-box;
}

a,
button {
  cursor: pointer;
}

p {
  word-wrap: break-word;
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.disabled {
  color: $gray-light;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  #main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @media all and (-ms-high-contrast: none) {
      *::-ms-backdrop, & { // IE11
        min-height: calc(100vh - 100px - 44.5px);
      }
    }
  }
}

#root {
  height: 100%;
}