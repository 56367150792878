.enrolement-header, .disconnected-header {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: $white;
  height: 100px;
  justify-content: space-between;
  align-items: center;

  &.border-bottom {
    border-bottom: 1px solid $gray-lighter;
  }

  & > div:first-child {
    display: flex;
    color: $gray-dark;
    padding: 1rem;
    justify-content: space-between;

    .logo-container {
      display: flex;
      width: 8rem;

      .logo {
        width: 5rem;
        height: 5rem;
        background: url('../img/logo_cprn.png') no-repeat;
        background-size: contain;
        margin-right: 2rem;
      }

      .line {
        border-left: 2px solid $yellow-secondary;
        height: 100%;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 900px;
      padding-left: 2rem;
      width: 100%;

      h1 {
        color: $blue-cobalt;
        text-transform: uppercase;
        margin-top: 8px;
        margin-bottom: 0;
        font-size: 1.2rem;
      }

      h2 {
        margin: 2px 0 0;
        font-size: 1rem;
      }
    }
  }

  .cartouche {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.disconnected-header {
  height: 120px;

  & > div:first-child {
    padding: 1rem 3rem;

    .logo-container {
      align-items: center;
      height: 7rem;

      .logo {
        width: 7rem;
        height: 90%;
        margin-right: 1rem;
      }

      .line {
        height: 75%;
      }
    }

    .title {
      h1 {
        font-size: $font-size-large;
        color: $gray-light;
        text-transform: none;
        padding-bottom: 1rem;
      }
      h2 {
        font-size: 16px;
        color: black;
        font-weight: 400;
      }
    }
  }
}

@media screen and (max-width: $desktop-width) {
  .enrolement-header, .disconnected-header {
    & > div:first-child {
      .logo-container {
        .logo {
          width: 6.3vw;
          height: 6.3vw;
        }
      }
      .title {
        margin: 0 5rem 0 0;
        h1 {
          font-size: 1.5vw;
        }
        h2 {
          margin-top: 0;
          font-size: 1.2vw;
        }
      }
    }
  }
}

@media screen and (max-width: $touchpad-width) {
  .enrolement-header, .disconnected-header {
    & > div:first-child {
      .logo-container {
        .logo {
          width: 4rem;
          height: 4rem;
        }
      }
      .title {
        h1 {
          font-size: 1.3rem;
        }
        h2 {
          font-size: 0.8rem;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .enrolement-header, .disconnected-header {
    & > div:first-child {
      .logo-container {
        width: 20vw;
        min-width: 4rem;
        .logo {
          width: 10vw;
          height: 10vw;
          min-width: 2rem;
          min-height: 2rem;
          margin: 0.8rem 1rem 0 0;
        }
      }
      .title {
        h1 {
          font-size: 1rem;
        }
        h2 {
          font-size: 0.7rem;
        }
      }
    }
  }
}