.timeline {
  display: flex;
  width: 100%;
  justify-content: space-around;
  position: absolute;
  left: 0;
  top: -25px;
  height: 50px;
  .timeline-item {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    background-color: white;
    color: $gray7;
    position: relative;
    figure {
      margin: 0;
      position: absolute;
      fill: $gray;
      width: 38px;
      height: 41px;
      @media all and (-ms-high-contrast: none) {
        *::-ms-backdrop, & { // IE11
          transform: translateY(-50%);
        }
      }
    }
    span {
      font-size: 0.8rem;
      z-index: 1;
      background-color: white;
      margin-left: 1.2rem;
    }
    &.active {
      figure {
        width: 70px;
        height: 75px;
        fill: $blue-cobalt;
      }
      span {
        font-size: 2rem;
        margin-left: 2.5rem;
        color: $blue-cobalt;
      }
    }
    &.complete, &.complete>figure {
      color: $green-success;
      fill: $green-success;
    }
  }
}

.vertical-timeline {
  max-width: 400px;
  height: 350px;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 1px solid black;
  .timeline-item {
    display: flex;
    padding: 2.5rem 0;
    margin-left: -36px;
    align-items: center;
    background-color: white;
    color: $gray7;
    fill: $gray7;
    position: relative;
    height: 32.727px;
    @media all and (-ms-high-contrast: none) {
      *::-ms-backdrop, &:not(:last-child) { // IE11
        margin-bottom: 20px;
      }
    }
    figure {
      position: absolute;
      height: 75px;
      width: 70px;
      margin: 0;
      @media all and (-ms-high-contrast: none) {
        *::-ms-backdrop, & { // IE11
          transform: translateY(-50%);
        }
      }
    }
    span {
      font-size: 1.5rem;
      z-index: 1;
      background-color: white;
      margin-left: 2.5rem;
    }
    &.active {
      figure {
        font-size: 6rem;
      }
      span {
        font-size: 2rem;
        margin-left: 3rem;
      }
    }
    &.complete, &.complete figure {
      color: $blue-cobalt;
      fill: $blue-cobalt;
    }
  }
}

@media screen and (max-width: $desktop-width) {
  .timeline {
    .timeline-item {
      figure {
        font-size: 3rem;
      }
      span {
        font-size: 0.7rem;
        margin-left: 1.6rem;
      }
      &.active {
        figure {
          font-size: 5rem;
        }
        span {
          font-size: 1.5rem;
          margin-left: 2.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: $touchpad-width) {
  .timeline .timeline-item:not(.active) {
    display: none;
  }

  .vertical-timeline {
    max-width: 90%;
    transform: translateX(36px);

    .timeline-item {
      span {
        font-size: 15px;
      }

      &.active {
        span {
          font-size: 18px;
        }
      }
    }
  }
}