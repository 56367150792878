.DateRangePicker {
  width: 100%;
  .DateRangePickerInput {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    width: 100%;
    .DateInput {
      width: 40%;
      font-size: 1rem;
    }
  }
}
